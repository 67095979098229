import React, { createContext, useEffect, useState, useRef } from 'react';
const DataContext = createContext();

export const DataProvider = ({ children }) => {

  
  const [toggleLocationModal, setToggleLocationModal] = useState(false);
  const [spinnerCubLoader, setSpinnerCubLoader] = useState(false);
  const [toggleCartModal, setToggleCartModal] = useState(false);
  const [toggleCategoryModal, setToggleCategoryModal] = useState(false);
  const [toggleServiceModal, setToggleServiceModal] = useState(false);
  const [productData, setProductData] = useState({});
  const [userToken, setUserToken] = useState('');
  const [locationPermissionStatus, setlocationPermissionStatus] = useState(false);
  const [selectedCity, setselectedCity] = useState('');
  const [toggleLoginModal, setToggleLoginModal] = useState(false);
  const [toggleAddressModal, setToggleAddressModal] = useState(false);
  const [couponSession, setCouponSession] = useState({ discount_amount: 0.00, promo_id: 0, promo_code: "", cart_amount: 0.00 });
  const [cartSessionData, setCartSessionData] = useState([]);
  const [cartSummary, setCartSummary] = useState({});
  const [cartCount, setCartCount] = useState(0);
  const [userAddressList, setUserAddressList] = useState([]);
  const [currentPosition, setcurrentPosition]= useState({
    lat:'',
    lng:''
})
 



  return (
    <DataContext.Provider value={
      {
       
        toggleLocationModal, setToggleLocationModal,
        toggleCategoryModal, setToggleCategoryModal,
        toggleServiceModal, setToggleServiceModal,
        toggleLoginModal, setToggleLoginModal,
        productData,setProductData,
        cartCount, setCartCount,
        cartSummary, setCartSummary,
        cartSessionData,setCartSessionData,
        couponSession,setCouponSession,
        userToken, setUserToken,
        selectedCity, setselectedCity,
        toggleCartModal, setToggleCartModal,
        toggleAddressModal, setToggleAddressModal,
        spinnerCubLoader, setSpinnerCubLoader,
        userAddressList,setUserAddressList,
        locationPermissionStatus,setlocationPermissionStatus,
        currentPosition, setcurrentPosition

      }
    }>
      {children}
    </DataContext.Provider>
  );
};

export default DataContext;