import React, { useEffect, useRef } from 'react';
import { ApiService } from '../../Components/Services/apiservices';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { Alert, Button, Col } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
    contact_name: yup.string().required('Name is required.'),
    contact_email: yup.string().email('Invalid email address').required('Email is required.'),
    contact_mobile: yup.string().matches(/^\d{10}$/, 'Mobile number must be 10 digits').required('Mobile number is required.'),
    contact_subject: yup.string().required('Subject is required.'),
    contact_message: yup.string().required('Message is required.'),
});

function ContactUs() {
    const didMountRef = useRef(true);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [successMessage, setSuccessMessage] = React.useState("");

    useEffect(() => {
        if (didMountRef.current) {
            getPagesData();
            didMountRef.current = false;
        }
    }, []);
    useEffect(() => {
        let timeout;
        if (successMessage) {
            timeout = setTimeout(() => {
                setSuccessMessage("");
            }, 2000);
        }
        return () => clearTimeout(timeout);
    }, [successMessage]);
    const getPagesData = () => {
        const dataString = {
            slug: "contact-us",
        };

        ApiService.postData("page-data", dataString).then((res) => {
            if (res.status === "success") {
                console.log("Data fetched successfully:", res.data);
            }
        }).catch((error) => {
            console.error("Error fetching page data:", error);
        });
    };

    const formik = useFormik({
        initialValues: {
            contact_name: '',
            contact_email: '',
            contact_mobile: '',
            contact_subject: '',
            contact_message: '',
        },
        validationSchema,
        onSubmit: (values) => {
            console.log("Form submitted with data:", values);

            ApiService.postData("contact-submit", values).then((res) => {
                if (res.status === "success") {
                    console.log("Form submitted successfully:", res.data);
                    setSuccessMessage("Your message has been sent successfully!");
                    setErrorMessage("");
                    formik.resetForm();
                } else {
                    console.error("Form submission failed:", res.message);
                    setErrorMessage(res.message || "Form submission failed.");
                    setSuccessMessage("");
                }
            }).catch((error) => {
                console.error("Error submitting form:", error);
                setErrorMessage("An error occurred while submitting the form.");
                setSuccessMessage("");
            });
        },
    });

    return (
        <>
            <Header />
            <section className='section-gap-medium'>
                <div className='container'>
                    <Col lg={7}>
                        <div className="section-title">
                            <h4>Get In Touch With Us</h4>
                            <p>
                                For any feedback, queries or to get help,
                                please call or Whatsapp us at
                                9876543210
                            </p>
                            <p>
                                <strong>
                                    Timings 10AM - 5PM (Tuesday to Sunday)
                                </strong>
                            </p>
                        </div>
                        {errorMessage && (
                            <Alert variant="danger">{errorMessage}</Alert>
                        )}
                        {successMessage && (
                            <Alert variant="success">{successMessage}</Alert>
                        )}
                        <div className='conset'>
                            <div className="contact-form form-vertical">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row g-3">
                                        <div className="col-lg-12">
                                            <div className="form-group-dark">
                                                <input
                                                    type="text"
                                                    name="contact_name"
                                                    className={`form-control ${formik.errors.contact_name ? 'is-invalid' : ''}`}
                                                    value={formik.values.contact_name}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Name*"
                                                />
                                                {formik.touched.contact_name && formik.errors.contact_name ? (
                                                    <div className="invalid-feedback">{formik.errors.contact_name}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group-dark">
                                                <input
                                                    type="text"
                                                    name="contact_email"
                                                    className={`form-control ${formik.errors.contact_email ? 'is-invalid' : ''}`}
                                                    value={formik.values.contact_email}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Email*"
                                                />
                                                {formik.touched.contact_email && formik.errors.contact_email ? (
                                                    <div className="invalid-feedback">{formik.errors.contact_email}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group-dark">
                                                <input
                                                    type="text"
                                                    name="contact_mobile"
                                                    className={`form-control ${formik.errors.contact_mobile ? 'is-invalid' : ''}`}
                                                    value={formik.values.contact_mobile}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Phone*"
                                                />
                                                {formik.touched.contact_mobile && formik.errors.contact_mobile ? (
                                                    <div className="invalid-feedback">{formik.errors.contact_mobile}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="form-group-dark">
                                                <input
                                                    type="text"
                                                    name="contact_subject"
                                                    className={`form-control ${formik.errors.contact_subject ? 'is-invalid' : ''}`}
                                                    value={formik.values.contact_subject}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Subject*"
                                                />
                                                {formik.touched.contact_subject && formik.errors.contact_subject ? (
                                                    <div className="invalid-feedback">{formik.errors.contact_subject}</div>
                                                ) : null}
                                            </div>
                                        </div>

                                        <div className="col-lg-12">
                                            <div className="form-group-dark">
                                                <textarea
                                                    name="contact_message"
                                                    className={`form-control ${formik.errors.contact_message ? 'is-invalid' : ''}`}
                                                    value={formik.values.contact_message}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    placeholder="Message*"
                                                />
                                                {formik.touched.contact_message && formik.errors.contact_message ? (
                                                    <div className="invalid-feedback">{formik.errors.contact_message}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <Button
                                                className="btn btn-primary btn-medium"
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </Col>
                </div>
            </section>
            <Footer />
        </>
    );
}

export default ContactUs;
