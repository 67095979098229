import React from 'react'
import { BrowserView } from 'react-device-detect'
import Header from '../../Components/Header'
import Accountsidebar from './account_sidebar'
import Footer from '../../Components/Footer'


function Order() {
  return (
    <BrowserView>

    <Header />
    <section className='section-gap-medium'>
      <div className="container">
        <div className="row">
          <div className="col-3">
            <Accountsidebar></Accountsidebar>
          </div>
          <div className="col-9">
            <p>Order</p>
          </div>
        </div>
      </div>
      </section>
    <Footer />
  </BrowserView>

  )
}

export default Order