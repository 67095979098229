import React, { useState, useRef, useEffect } from 'react'
import { BrowserView } from 'react-device-detect'
import Header from '../../Components/Header'
import Accountsidebar from './account_sidebar'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import { Alert, Button } from 'react-bootstrap';




function Profile() {
  const [userDetail, setUserDetails] = useState({
    user_fname: "",
    user_email: "",
    user_mobile: ''
  });
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [successMessage, setSuccessMessage] = useState('')
  const didMountRef = useRef(true);

  useEffect(() => {
    if (didMountRef.current) {
      getUserData()
    }
    didMountRef.current = false;
  }, []);

  const getUserData = () => {
    ApiService.fetchData('user-data').then((res) => {
      if (res.status == 'success') {
        setUserDetails(res.rowUserData)
      }
      else if (res.status == 'error' && res.message == 'Session expired') {
        localStorage.removeItem('USER_TOKEN')
        window.location.href = '/'
      }
    }).catch((error) => {

    })
  }
  const onSignUpInputChange = (e) => {

    setErrorMessage('')
    setSuccessMessage('')
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value !== '') {
      e.target.style.border = '';
    }
  };
  const userUpdateProcess = (e) => {
    let counter = 0;
    const myElements = document.getElementsByClassName('registerrequired');
    for (let i = 0; i < myElements.length; i++) {
      if (myElements[i].value === '') {
        myElements[i].style.border = '1px solid red';
        counter++;
      } else {
        myElements[i].style.border = '';
      }
    }
    if (counter == 0) {
      setIsLoading(true)
      ApiService.postData("update-user-profile", userDetail).then((res) => {
        if (res.status === "success") {
          setSuccessMessage(res.message)
          // setUserDetails(res.rowUserData)
        }
        else if (res.status == 'error' && res.message == 'Session expired') {
          setErrorMessage(res.message);
          setTimeout(() => {
            localStorage.removeItem("USER_TOKEN");
            window.location.reload();
          }, 500);
        }
        else {
          setErrorMessage(res.message);
          setTimeout(() => {
            setIsLoading(false)
          }, 500);
        }
      }).catch(() => {
        setIsLoading(false)
      });
    }

  }
  return (
    <BrowserView>
      <Header />
      <section className='section-gap-medium'>
        <div className="container">
          <div className="row">
            <div className="col-3">
              <Accountsidebar></Accountsidebar>
            </div>
            <div className="col-9">
              {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
              {successMessage && (<Alert variant="success">{successMessage}</Alert>)}

              <div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    name="user_fname"
                    className="form-control registerrequired"
                    value={userDetail.user_fname}
                    onChange={(e) => onSignUpInputChange(e)}
                    placeholder="Full Name"
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    type="text"
                    name="user_email"
                    className="form-control registerrequired"
                    disabled={true}
                    readOnly
                    value={userDetail.user_email}
                    onChange={(e) => onSignUpInputChange(e)}
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-group mb-3">
                  <input
                    type="number"
                    name="user_mobile"
                    className="form-control registerrequired"
                    disabled={true}
                    readOnly
                    value={userDetail.user_mobile}
                    onChange={(e) => onSignUpInputChange(e)}
                    placeholder="Mobile"
                  />
                </div>
                <div>
                  <button className='btn btn-primary btn-medium mt-4' onClick={() => { userUpdateProcess() }}>Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />


    </BrowserView>
  )
}

export default Profile