import { useContext, useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import DataContext from '../Elements/context';
import { Alert, Button } from 'react-bootstrap';
import { ApiService } from '../Services/apiservices';
import OTPInput from 'react-otp-input';
import { validEmail, validNumber } from '../../Components/Elements/Regex';
import sessionCartData from '../Elements/cart_session_data';


function LoginModal({ show, setShow, handleClose, }) {
    const [errorMessage, setErrorMessage] = useState("");
    const cartSessionData = sessionCartData();
    const [successMessage, setSuccessMessage] = useState("");
    const didMountRef = useRef(true)
    const [steps, setSteps] = useState(1);
    const [spinnerLoading, setspinnerLoading] = useState(false);
    const [resendTimer, setResendTimer] = useState(30);
    const inputRef = useRef(null);
    const [regDetails, setRegDetails] = useState({
        user_fname: "",
        user_email: "",
    });
    const [isLoading, setIsLoading] = useState(false);

    const [otp, setOtp] = useState('');

    const [loginusermobile, setLoginusermobile] = useState('');

    const onChangeOtp = (otp) => {
        setErrorMessage("");
        setSuccessMessage('')
        setOtp(otp);
        if (otp.length === 4) {
            userLoginOtpProcess(otp);
        }
    };

    const contextValues = useContext(DataContext)

    const userLoginprocess = () => {
        if (loginusermobile === '') {
            if (inputRef.current) {
                inputRef.current.style.border = '1px solid red';
            }
            return false;
        }
        if (!validNumber.test(loginusermobile)) {
            setErrorMessage("Please enter valid Mobile Number");
            return false;
        }
        setIsLoading(true)
        const dataString = {
            user_mobile: loginusermobile
        }
        ApiService.loginProccessPostData("user-login-process", dataString).then((res) => {
            if (res.status === "success") {
                localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
                setErrorMessage('')
                setSteps(2);
                setIsLoading(false)
                setResendTimer(30);
            } else {
                setErrorMessage(res.message);
                setIsLoading(false)
            }
        }).catch(() => {
            setIsLoading(false)
        })
    }


    const userLoginOtpProcess = (otp) => {
        setspinnerLoading(true)
        const dataString = {
            user_otp: otp,
        };
        ApiService.loginProccessPostData("otpverify", dataString).then((res) => {
            if (res.status === "success") {
                if (res.user_status === 'new_register') {
                    setTimeout(() => {
                        localStorage.setItem("TEMP_USER_TOKEN", res.user_token);
                        setSteps(3)
                        setspinnerLoading(false)
                    }, 500);
                } else if (res.user_status == 'already_registered') {
                    localStorage.setItem("USER_TOKEN", res.user_token);
                    setSuccessMessage(res.message)
                    setspinnerLoading(false)
                    sendCartSessionData()
                    setTimeout(() => {
                        setSuccessMessage('')
                        window.location.reload();
                    }, 500);
                }
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                setErrorMessage(res.message)
                setspinnerLoading(false)
                setTimeout(() => {
                    setErrorMessage('')
                    localStorage.removeItem("TEMP_USER_TOKEN");
                    window.location.reload();
                }, 500);
            }
            else {
                setTimeout(() => {
                    setErrorMessage(res.message);
                    setspinnerLoading(false)
                }, 500);
            }
        }).catch(() => {
            setspinnerLoading(false)
        });

    };

    useEffect(() => {
        const timerId = setInterval(() => {
            if (resendTimer > 0) {
                setResendTimer(prev => prev - 1);
            }
        }, 1000);
        return () => {
            clearInterval(timerId);
        };
    }, [resendTimer]);

    const sendCartSessionData = () => {
       
        ApiService.postData('cartToUser', cartSessionData[1]).then((res) => {
            if (res.success == 'Cart updated successfully') {
                localStorage.removeItem("CART_SESSION");
            }
            else if(res.status == 'error' && res.message == 'Session expired'){
                setErrorMessage(res.message)
                setTimeout(() => {
                    setErrorMessage('')
                    localStorage.removeItem("USER_TOKEN");
                    window.location.reload();
                }, 500); 
            }
        })
    }
    const resendOTP = () => {
        setErrorMessage("");
        setResendTimer(30);
        setOtp("");
        setspinnerLoading(true)
        const dataString = {
            user_token: '',
        }
        ApiService.loginProccessPostData('resendotp', dataString).then((res) => {
            if (res.status === "success") {
                setTimeout(() => {
                    setspinnerLoading(false);
                }, 200);
            } else if (res.status == 'error' && res.message == 'Session expired') {
                setErrorMessage(res.message);
                setTimeout(() => {
                    setErrorMessage('')
                    localStorage.removeItem("TEMP_USER_TOKEN");
                    window.location.reload();
                }, 500);
            }
            else {
                setErrorMessage(res.message);
                setTimeout(() => {
                    setspinnerLoading(false);
                }, 500);
            }
        }).catch(() => {
            setspinnerLoading(false)
        });
    }

    const onSignUpInputChange = (e) => {

        setErrorMessage('')
        setSuccessMessage('')
        const { name, value } = e.target;
        setRegDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (value !== '') {
            e.target.style.border = '';
        }
    };

    const userRegisterProcess = (e) => {
        let counter = 0;
        const myElements = document.getElementsByClassName('registerrequired');
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {

            if (!validEmail.test(regDetails.user_email)) {
                setErrorMessage("Please Enter Valid Email");
                return false;
            }

            setIsLoading(true)
            ApiService.loginProccessPostData("user-register-process", regDetails).then((res) => {
                if (res.status === "success") {
                    localStorage.removeItem("TEMP_USER_TOKEN");
                    setSuccessMessage(res.message)
                    localStorage.setItem("USER_TOKEN", res.user_token);
                    contextValues.setUserToken(res.user_token)
                    sendCartSessionData()
                    setTimeout(() => {
                       setSuccessMessage('')
                        window.location.reload();
                    }, 500);
                }
                else if (res.status == 'error' && res.message == 'Session expired') {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        localStorage.removeItem("TEMP_USER_TOKEN");
                        window.location.reload();
                    }, 500);
                }
                else {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500);
                }
            }).catch(() => {
                setIsLoading(false)
            });
        }

    }
    const onLoginInput = (e) => {
        setLoginusermobile(e.target.value)
        if (e.target.value !== '') {
            e.target.style.border = '';
        }
        setErrorMessage('')
    }

    const goBack = () => {
        setErrorMessage("");
        setSuccessMessage('')
        setLoginusermobile('')
        setRegDetails({
            user_fname: "",
            user_email: "",
        })
        setOtp("");
        setSteps(1);
    }
    return (
        <>
            <Modal className='loginModal' show={show} onHide={handleClose}>
                <button className="close-btn" onClick={(e) => handleClose()}><i className="ri-close-line"></i></button>
                <div className='loginContant'>
                    {steps == 1 &&
                        <>
                            <div className='text-center'>
                                <img src="/img/logo.png" className='mb-1' alt="" width="150" height="auto" />
                                <h5 className="tx-theme mt-4">Log in or Sign up</h5>
                                <p className="tx-color-02 mb-4">For Better Experience, Order tracking & Regular updates</p>
                            </div>
                            {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                            {successMessage && (<Alert variant="success">{successMessage}</Alert>)}

                            <div className="form-group country-input mb-4">
                                <input
                                    type="number"
                                    name="loginusermobile"
                                    ref={inputRef}
                                    min={0}
                                    onChange={(e) => { onLoginInput(e) }}
                                    placeholder="Enter Mobile Number"
                                />
                                <span className="country-code">+91</span>
                            </div>
                            <button className="btn btn-primary btn-full btn-lg mb-4" onClick={(e) => userLoginprocess()}>{isLoading ? (<img src="/img/loder01.gif" width="60px" height="11px" />) : ("Login")}</button>
                            <p className="text-center tx-12 tx-color-03 mb-0">By continuing I agree with the <a href="/privacy-policy" className="tx-theme">Privacy Policy</a> and <a href="/terms-conditions" className="tx-theme">Terms &amp; Conditions</a></p>
                        </>}

                    {steps == 2 && <>
                        <div className='text-center'>
                            <h5 className="mb-15">Verify Mobile number</h5>
                            <p className="tx-color-02 mb-15">
                                Otp has been sent to your registered mobile number {loginusermobile}.<a href='javascript:;' className='tx-primary' onClick={(e) => goBack()}>Change</a>
                            </p>
                        </div>
                        {errorMessage && (<Alert className="otp-error" variant="danger">{errorMessage}</Alert>)}
                        {successMessage && (<Alert className="otp-success" variant="success">{successMessage}</Alert>)}
                        <div className="form-group mb-15 otp-input">
                            <OTPInput
                                value={otp}
                                onChange={onChangeOtp}
                                numInputs={4}
                                renderInput={(props) => <input {...props} />}
                            />
                        </div>
                        {resendTimer === 0 ? (
                            <p className='tx-gray text-center mb-0'>
                                Did not receive OTP? <a href='javascript:void(0);' className='tx-primary' onClick={(e) => resendOTP()}>Resend OTP</a>
                            </p>
                        ) : (
                            <p className='tx-gray text-center mb-0'>Resend code in {resendTimer} sec</p>
                        )}
                        {spinnerLoading && (
                            <div className="siteloader tx-center">
                                <img src="/img/loader.webp" alt="Loading..." width="30" height="30" />
                            </div>
                        )}
                    </>}

                    {steps == 3 && <>
                        <div className="text-center mb-4">
                            <h5>Complete Your Registration</h5>
                            <p className="tx-color-02">
                                Complete your details and fill the form below
                            </p>
                        </div>
                        {errorMessage && (<Alert variant="danger">{errorMessage}</Alert>)}
                        {successMessage && (<Alert variant="success">{successMessage}</Alert>)}
                        <div className="form-group mb-4">
                            <input
                                type="text"
                                name="user_fname"
                                className="form-control registerrequired"
                                value={regDetails.user_fname}
                                onChange={(e) => onSignUpInputChange(e)}
                                placeholder="Full Name"
                            />
                        </div>
                        <div className="form-group mb-4">
                            <input
                                type="text"
                                name="user_email"
                                className="form-control registerrequired"
                                value={regDetails.user_email}
                                onChange={(e) => onSignUpInputChange(e)}
                                placeholder="Email Address"
                            />
                        </div>
                        <div className="d-grid">
                            <button className="btn btn-primary btn-full btn-lg mb-4" onClick={(e) => userRegisterProcess()}>Register</button>
                        </div>

                    </>}


                </div>
            </Modal>
        </>
    );
}

export default LoginModal;





