import React, { useEffect, useState, useRef } from "react";

import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from "../../Components/Services/apiservices";
import { useNavigate } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import MobileHeader from "../../Components/Header/mobile_header";
import Accountsidebar from "./account_sidebar";

function AccountOverview() {
  const didMountRef = useRef(true);
  const [rowUserData, setRowUserData] = useState({});
  const [spinnerLoading, setSpinnerLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
    
    }
    didMountRef.current = false;
  }, []);
 
  const redirectTo = (Route) => {
    navigate(Route);
  };
  return (
    <>
      <BrowserView>

        <Header />
        <section className='section-gap-medium'>
          <div className="container">
            <div className="row">
              <div className="col-3">
                <Accountsidebar></Accountsidebar>
              </div>
              <div className="col-9">
                <p>Account overview</p>
              </div>
            </div>
          </div>
          </section>
        <Footer />
      </BrowserView>

      <MobileView>
        <MobileHeader PageName="AccountOverview" />
        <div>AccountOverview</div>
      </MobileView>

    </>
  )
}

export default AccountOverview