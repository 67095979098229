import { useContext } from "react";
import constant from "../Services/constant";
import DataContext from "./context";

const ServiceBox = ({ service, index, serviceImgurl }) => {
    const contextValues = useContext(DataContext)
    const discount = (data) => {
        let mrpValue = parseFloat(data.service_price);
        let sellingPriceValue = parseFloat(data.service_selling_price);
        let discount = 0;

        if (!isNaN(mrpValue) && !isNaN(sellingPriceValue) && mrpValue > 0) {
            discount = Math.round((((mrpValue - sellingPriceValue) / mrpValue) * 100));
        }

        return discount
    }

    const serviceModal = (data) => {
        contextValues.setProductData(data)
        setTimeout(() => {
            contextValues.setToggleServiceModal(!contextValues.toggleServiceModal)
        }, 100)
    }



    return (<>
        <div className="tile" onClick={() => { serviceModal(service) }}>
            <picture>
                <img src={service.service_image ? serviceImgurl + service.service_image : constant.DEFAULT_IMAGE} alt={service.service_name} className="img-fluid" />
            </picture>
            <div className="tile-body">
                <h5>{service.service_name}</h5>
                {service.service_category_name && <p>{service.service_category_name}</p>}
                {/* {service.service_desc && <p dangerouslySetInnerHTML={{ __html: service.service_desc }}></p>} */}
                {discount(service) > 0 && (<div className="sale">
                    Sale: <span>{discount(service)}%</span>
                </div>)}

                <div className="price">
                    {parseFloat(service.service_price) > parseFloat(service.service_selling_price) && <del>₹{service.service_price}</del>}
                    <span className="ms-2">₹{service.service_selling_price}</span>
                </div>
            </div>
        </div>
    </>)
}


export default ServiceBox