import { useContext, useState, useEffect, useRef } from "react"
import DataContext from "../../Components/Elements/context"
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../Components/Services/apiservices";
import CartHeader from "../../Components/Header/cart_header";
import AddressModal from "../../Components/Modals/address_modal";
import AddressForm from "./component/address_form";
import OrderSummary from "./component/order_summary";
import Loader from "react-js-loader";


const CheckoutAddress = () => {
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    const [userData, setUserData] = useState({})
    const [showLoginPass, setshowLoginPass] = useState(false)
    const [isLoading, setisLoading] = useState(false)
    const [SpinnerLoading, setSpinnerLoading] = useState(false)
    const [userAddressList, setuserAddressList] = useState([])
    useEffect(() => {
        if (didMountRef.current) {
            getUserData()
            getuserAddress()
        }
        didMountRef.current = false;
    }, []);

    const getUserData = () => {
        ApiService.fetchData('user-data').then((res) => {
            if (res.status == 'success') {
                setUserData(res.rowUserData)
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                localStorage.removeItem('USER_TOKEN')
                window.location.href = '/'
            }
        }).catch((error) => {

        })
    }

    const getuserAddress = () => {
        setisLoading(true)
        ApiService.fetchData('get-user-address').then((res) => {
            if (res.status == 'success') {
                contextValues.setUserAddressList(res.resUserAddress);
                setuserAddressList(res.resUserAddress)
                setisLoading(false)
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                setisLoading(false)
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
            }
        }).catch(() => {
            setisLoading(false)
        })

    }


    const addressModal = () => {
        contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)
    }
    const selectAddress = (addressValue) => {
        const dataString = {
            ua_id: addressValue.ua_id,
        };
        ApiService.postData("chooseAddress", dataString).then((res) => {
            if (res.status === "success") {
                // contextValues.setDefaultAddressStatus(true)
                contextValues.setUserAddressList(res.resUserAddress);
                setuserAddressList(res.resUserAddress)
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 500);
            } else {
                setTimeout(() => {
                    setSpinnerLoading(false)
                }, 500);
            }
        }).catch((error) => {

        });
    }


    return (<>
        <CartHeader></CartHeader>
        <div className="container">
            <div className="checkout-wapper">
                <div className="row">
                    <div className="col-7">  <div className="left">
                        <div className="checkoutLogo" style={{ width: '200px' }}>
                            <a href="/">
                                <img src={'/img/logo.png'} style={{ width: '100%' }}></img>
                            </a>
                        </div>
                        <ul className="checkoutStep">
                            <li>
                                <a href="/address">Address</a>
                            </li>
                            <li>
                                <a href="javasctipt:void(0)">Payment</a>
                            </li>
                        </ul>
                        {isLoading ? <>
                        
                            <Loader type="spinner-default" bgColor={'orange'} color={'orange'} size={40} /> 
                        
                        </> : <>
                            {/* Address Section */}
                            {userAddressList.length > 0 ?
                                <>
                                    <div className="row g-3 mt-20">
                                        <div className="col-lg-12 ">
                                            <div className="cartSectionTitle">
                                                <h5 className="mb-0 tx-18">Select Delivery Address</h5>
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row g-3 mt-2">
                                                {userAddressList.length > 0 && userAddressList.map((value, index) => (
                                                    <div className="col-lg-6">
                                                        <div className={`cartAddressbox ${value.ua_default_address == 1 ? 'active' : ''}`} key={index}
                                                            onClick={(e) => selectAddress(value)}

                                                        >
                                                            <div className="radioBox">
                                                                <input type="radio" checked={value.ua_default_address == 1 ? true : false}></input>
                                                                <label for="radio-4" className="radio-label"></label>
                                                            </div>
                                                            <div className="addressbox mb-2">
                                                                <div className="addressbox-body">
                                                                    <h6 className="tx-gray mb-1 fw400 tx-uppercase">{value?.ua_name ? value?.ua_name : ''}</h6>
                                                                    <p className="tx-gray mb-1">{value?.ua_house_no}, {value?.ua_area}, {value?.ua_city_name}</p>
                                                                    <p className=" tx-gray mb-1 tx-14">{value.ua_state_name}, {value.ua_country_name} - {value.ua_pincode}</p>
                                                                    <p className="tx-gray mb-0 tx-14">Mobile No: {value.ua_mobile}</p>
                                                                    {value?.ua_email ? <p className=" tx-gray mb-0 tx-14">Email Address: <span className="font-third">{value?.ua_email}</span></p> : ''}
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <button type="button" className="btn btn-primary-outline btn-medium d-flex align-items-center mb-50 mt-20"
                                                onClick={(e) => addressModal()}
                                            ><i className="ri-add-circle-line ri-lg mr-5"></i>Add New Address</button>
                                        </div>
                                    </div>
                                    <div className="row g-3">
                                        <div className="col-lg-12">
                                            <button type="button" className="btn btn-primary btn-lg btn-full"
                                                onClick={() => { navigate('/checkout') }}

                                            >CONTINUE TO PAY</button>
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <AddressForm />

                                </>
                            }




                        </>}



                    </div></div>
                    <div className="col-5">
                        <OrderSummary></OrderSummary>

                    </div>
                </div>


            </div>


        </div>

        {contextValues.toggleAddressModal && (<AddressModal></AddressModal>)}
    </>)

}
export default CheckoutAddress