import React, { useEffect, useRef, useState } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { ApiService } from '../../Components/Services/apiservices'
import constant from '../../Components/Services/constant'
import CategoryModal from '../../Components/Modals/category_modal'
import Skeleton from 'react-loading-skeleton'
function HomeCleaningService() {
    const didMountRef = useRef(true)
    const [featuredCategory, setFeaturedCategory] = useState([])
    const [imageUrl, setImageUrl] = useState("")
    const [categoryChildren, setCategoryChildren] = useState([]);
    const [isLoading, setisLoading] = useState(false)
    const [modalCategory, setModalCategory] = useState(null)
    const [showModal, setShowModal] = useState(false)
    useEffect(() => {
        if (didMountRef.current) {
            getFeaturedCategory()
        }
        didMountRef.current = false
    }, [])

    const getFeaturedCategory = () => {
        setisLoading(true)
        ApiService.fetchData("featuredCategory").then((res) => {
            if (res.status == "success") {
                setFeaturedCategory(res.data)
                setisLoading(false)
                const allChildren = res.data.map(category => category.children || []);
                setCategoryChildren(allChildren);
                setImageUrl(res.imgUrl)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }
    const handleCategoryClick = (category) => {
        if (category.children && category.children.length > 0) {
            setModalCategory(category)
            setShowModal(true)
        } else {
            window.location.href = `/services/${category.cat_slug}`
        }
    }
    return (
        <>

            <BrowserView>
                {isLoading ? <>
                    <section className='section-gap-medium'>
                        <div className='container'>
                            <div className='section-title tx-center mb-30'>
                                <h6 className='tx-primary'><Skeleton width={'100px'}></Skeleton> </h6>
                                <h2><Skeleton width={'200px'}></Skeleton> </h2>
                                <p className='tx-gray'><Skeleton width={'300px'}></Skeleton> </p>
                            </div>
                            <div className='grid-categories'>
                                {[...Array(9)].map((_, index) => {
                                    return (
                                        <>
                                            {index < 6 ? <>
                                                <a href='#' className={'categoriesbox item-' + (index + Number(1))} >
                                                    <div className='categoriesboxInner'>
                                                        <div className=''>
                                                            <Skeleton width={'200px'} height={'100px'} ></Skeleton>
                                                        </div>
                                                        <h6 className='title mb-0 mt-2'> <Skeleton width={'100px'} height={'20px'}></Skeleton></h6>
                                                    </div>

                                                </a>



                                            </> : <> <a href='javascript:void(0)' className={'categoriesbox item-' + (index + Number(1))} >
                                                <div className='categoriesboxInner'>
                                                    <div className=''>
                                                        <Skeleton width={'400px'} height={'100px'} ></Skeleton>
                                                    </div>
                                                    <h6 className='title mb-0 mt-2'> <Skeleton width={'200px'} height={'20px'}></Skeleton></h6>
                                                </div>

                                            </a></>}

                                        </>
                                    )


                                })}
                            </div>

                        </div>
                    </section>
                </> : <>  {featuredCategory && featuredCategory.length > 0 ? (
                    <section className='section-gap-medium'>
                        <div className='container'>
                            <div className='section-title tx-center mb-30'>
                                <h6 className='tx-primary'>Affordable Prices </h6>
                                <h2>Our Cleaning services at your doorstep </h2>
                                <p className='tx-gray'>Our prices are simple and affordable which are easy on pocket<br></br>
                                    in comparison with the high street prices </p>
                            </div>
                            <div className='grid-categories'>
                                {featuredCategory.map((category, index) => (
                                    <a href='javascript:void(0)' className={'categoriesbox item-' + (index + Number(1))} onClick={(e) => { e.preventDefault(); handleCategoryClick(category); }}>
                                        <div className='categoriesboxInner'>
                                            <div className='categoriesboxIcon'>
                                                <img src={category.cat_image ? imageUrl + category.cat_image : constant.DEFAULT_IMAGE} />
                                            </div>
                                            <h6 className='title mb-0'>{category.cat_name}</h6>
                                        </div>

                                    </a>
                                ))}
                            </div>

                        </div>
                    </section>
                ) : null}</>}


            </BrowserView>
            <MobileView>
                {isLoading ? <>
                    <section className='section-gap-small'>
                        <div className='container'>
                            <div className='section-title tx-center mb-30'>
                                <h6 className='tx-primary'><Skeleton width={'100px'}></Skeleton> </h6>
                                <h2><Skeleton width={'200px'}></Skeleton> </h2>
                                <p className='tx-gray'><Skeleton width={'300px'}></Skeleton></p>
                            </div>
                            <div className='row g-3 cols-3'>
                                {[...Array(9)].map((__, index) => (
                                    <a href='javascript:void(0)' className='categoriesbox' key={index}>
                                        <div className='categoriesboxInner'>
                                            <div className=''>
                                                <Skeleton width={'80px'} height={'70px'} ></Skeleton>
                                            </div>
                                            <h6 className='title mb-0 mt-1'><Skeleton width={'80px'} height={'20px'}></Skeleton></h6>
                                        </div>

                                    </a>
                                ))}
                            </div>
                        </div>
                    </section>



                </> : <>{featuredCategory && featuredCategory.length > 0 ? (
                    <section className='section-gap-small'>
                        <div className='container'>
                            <div className='section-title tx-center mb-30'>
                                <h6 className='tx-primary'>Affordable Prices </h6>
                                <h2>Our Cleaning services at your doorstep </h2>
                                <p className='tx-gray'>Our prices are simple and affordable which are easy on pocket in comparison with the high street prices </p>
                            </div>
                            <div className='row g-3 cols-3'>
                                {featuredCategory.map((category, index) => (
                                    <a href='javascript:void(0)' className='categoriesbox'key={index} onClick={(e) => { e.preventDefault(); handleCategoryClick(category); }}>
                                        <div className='categoriesboxInner'>
                                            <div className='categoriesboxIcon'>
                                                <img src={category.cat_image ? imageUrl + category.cat_image : constant.DEFAULT_IMAGE} />
                                            </div>
                                            <h6 className='title mb-0'>{category.cat_name}</h6>
                                        </div>

                                    </a>
                                ))}
                            </div>
                        </div>
                    </section>
                ) : null}</>}


            </MobileView>
            {showModal && modalCategory && (
                <CategoryModal
                    category={modalCategory}
                    onClose={() => setShowModal(false)}
                    categoryChildren={modalCategory.children || []}
                    imageUrl={imageUrl}

                />
            )}
        </>


    )
}

export default HomeCleaningService