import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { BrowserView, MobileView } from 'react-device-detect';
import HomeTopBanner from './home_top_banner';
import HomeCleaningService from './home_cleaning_service';
import HomeTestimonial from './home_testimonial';
import HomeAddBanner from './home_add_banner';
import HomeFeaturedServices from './home_featured_services';
import HomeBottomBanner from './home_bottom_banner';

function Home() {
    return (
        <>
            <BrowserView>
                <Header />
                <HomeTopBanner />
                <HomeCleaningService />
                <HomeAddBanner />
                <HomeFeaturedServices/>
                <HomeTestimonial />
                <HomeBottomBanner></HomeBottomBanner>
               
                <Footer />
                <div className="bubbleContainer">
                    <div className="bubble-1"></div>
                    <div className="bubble-2"></div>
                    <div className="bubble-3"></div>
                    <div className="bubble-4"></div>
                    <div className="bubble-5"></div>
                    <div className="bubble-6"></div>
                    <div className="bubble-7"></div>
                    <div className="bubble-8"></div>
                    <div className="bubble-9"></div>
                    <div className="bubble-10"></div>
                </div>
            </BrowserView>

            <MobileView>
                <Header />
                <HomeTopBanner />
                <HomeCleaningService />
                <HomeAddBanner />
                <HomeFeaturedServices/>
                <HomeTestimonial />
                {/* <HomeBottomBanner></HomeBottomBanner> */}
                <Footer />
                <div className="bubbleContainer">
                    <div className="bubble-1"></div>
                    <div className="bubble-2"></div>
                    <div className="bubble-3"></div>
                    <div className="bubble-4"></div>
                    <div className="bubble-5"></div>
                    <div className="bubble-6"></div>
                    <div className="bubble-7"></div>
                    <div className="bubble-8"></div>
                    <div className="bubble-9"></div>
                    <div className="bubble-10"></div>
                </div>
            </MobileView>

        </>
    )

}
export default Home 