import { useContext, useEffect } from "react"
import ServicesModal from "../Modals/services_modal"
import DataContext from "../Elements/context"

const CartHeader=()=>{
    const contextValues = useContext(DataContext)
    useEffect(()=>{
        if (localStorage.getItem("USER_TOKEN")) {
            contextValues.setUserToken(localStorage.getItem("USER_TOKEN"));
        } 
    }, [])

    return(<>
    <p>Cart Header</p>
   {contextValues.toggleServiceModal && <ServicesModal></ServicesModal>} 
    </>)
}

export default CartHeader