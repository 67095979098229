import Footer from "../../Components/Footer/index";
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import sessionCartData from "../../Components/Elements/cart_session_data";
import DataContext from "../../Components/Elements/context";
import { addToCart, addToCartSession, minusToCart, minusToCartSession, removeToCart, removeToCartSession } from "../../Components/Elements/add_to_cart";
import { toast } from "react-toastify";
import Loader from "react-js-loader";
import multiCurrency from "../../Components/Elements/multiCurrency";
import { ApiService } from "../../Components/Services/apiservices";
import CartHeader from '../../Components/Header/cart_header'
import LoginModal from "../../Components/Modals/login_modal";

const Cart = () => {
    const dataArray = sessionCartData();
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [variation, setvariation] = useState([])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const navigate = useNavigate()
    const didMountRef = useRef(true)

    const contextValues = useContext(DataContext)
    const sliderRef = useRef(null);
    useEffect(() => {
        if (didMountRef.current) {
            if (localStorage.getItem("USER_TOKEN")) {
                getCartSessionData();
            } else {
                contextValues.setCartSessionData(dataArray[1])
                contextValues.setCartCount(dataArray[1].length)
                contextValues.setCartSummary(dataArray[3])
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
           
        }
        didMountRef.current = false;
    }, []);

    const getCartSessionData = () => {
        const dataString = {
            coupon_session: localStorage.getItem("COUPON_SESSION"),
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                contextValues.setCartSessionData([])
                contextValues.setCartCount(0)
                contextValues.setCartSummary({})
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    }

  





    const addToCartProcess = async (serviceData) => {
      
        if (parseFloat(serviceData.service_selling_price) > 0) {
            const productData = {
                service_id: Number(serviceData.service_id),
                service_name: serviceData.service_name,
                service_slug: serviceData.service_slug,
                service_image: serviceData?.service_image ,
                service_type: Number(serviceData.service_type),
                service_price: parseFloat(serviceData.service_price).toFixed(2),
                service_selling_price: parseFloat(serviceData.service_selling_price).toFixed(2),
                service_discount: serviceData.service_discount,
                service_variation: serviceData.service_variation,
                service_category_id: serviceData.service_category_id,
                selected_variation: serviceData.selected_variation,
                quantity: serviceData.quantity,
            };
            contextValues.setSpinnerCubLoader(serviceData.service_id)
            setvariation(serviceData.selected_variation)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                   
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                   
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };


    const minusToCartProcess = async (addservice) => {
        if (parseFloat(addservice.service_selling_price) > 0) {
            contextValues.setSpinnerCubLoader(addservice.service_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await minusToCartSession(addservice, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await minusToCart(addservice, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const removeToCartProcess = async (serviceData) => {
        setSpinnerLoading(false);
        if (localStorage.getItem("USER_TOKEN")) {
            const updateStatus = await removeToCartSession(serviceData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        } else {
            const updateStatus = await removeToCart(serviceData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        }
    };


    const serviceModal = (data) => {
        contextValues.setProductData(data)
        setTimeout(() => {
            contextValues.setToggleServiceModal(!contextValues.toggleServiceModal)
        }, 100)
    }

 
    return (<>
        <CartHeader></CartHeader>
        <section className="section-gap-md">
            {contextValues.cartSessionData.length > 0 ?
                <>
                    <div className="container-fluid">
                        <div className="section-title mb-40">
                            <h4>Shopping Bag {contextValues.cartSessionData && contextValues.cartSessionData.length > 0 && (contextValues.cartSessionData.length)}</h4>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-lg-8">
                                <table className="shop-table cart-table">
                                    <thead>
                                        <tr>
                                            <th><span>Service</span></th>
                                            <th></th>
                                            <th><span>Price</span></th>
                                            <th><span>quantity</span></th>
                                            <th className="tx-right">total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contextValues.cartSessionData.map((value, index) => {
                                            return (
                                                <tr>
                                                    <td className="service-thumbnail">
                                                        <figure>
                                                            <a href='javacript:void(0)' onClick={()=>{serviceModal(value)}}>
                                                                <img src={value.service_image}></img>
                                                            </a>
                                                        </figure>
                                                    </td>
                                                    <td className="service-name">
                                                        <h2 className="service-name">
                                                            <a href={'/service/' + value.service_slug}>{value.service_name}</a>
                                                        </h2>
                                                        {value.service_type == 1 && value.selected_variation && value.selected_variation.length > 0 ?
                                                            <>
                                                                {value.selected_variation.map((value, index) => {
                                                                    return (<p className="tx-14" key={index}>{value.attr}: {value.terms}</p>)
                                                                })}
                                                            </>
                                                            : null}
                                                    </td>
                                                    <td className="service-price">
                                                        <span className="amount">{multiCurrency(value.service_selling_price)}</span>
                                                    </td>
                                                    <td className="service-quantity">
                                                        <div className="cartProductqty">
                                                            <span onClick={(e) => minusToCartProcess(value)}><i className="ri-subtract-line"></i></span>
                                                            <span>{contextValues.spinnerCubLoader == value.product_id && variation==value.selected_variation ? <div className='buttonLoader'><Loader type="spinner-default" bgColor={'#121212'} color={'#121212'} size={30} /> </div> : value.quantity}</span>
                                                            <span onClick={(e) => addToCartProcess(value)}><i className="ri-add-line"></i></span>
                                                        </div>
                                                    </td>
                                                    <td className="service-price tx-right">
                                                        <div className="priceTotalTable">
                                                            <span className="amount">{multiCurrency(parseFloat(value.service_selling_price) * Number(value.quantity))}</span>
                                                            <a href="javascript:void(0)" onClick={(e) => removeToCartProcess(value)} className="service-remove" title="Remove this service">
                                                                <i className="ri-delete-bin-6-line"></i>
                                                            </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="col-lg-3">
                                <div className="cartSummaryBox">
                                    <ul>
                                        <li>
                                            <span className="tx-18 fw600">SUBTOTAL</span>
                                            <span className="tx-18 fw600">{multiCurrency(contextValues.cartSummary.itemTotal)}</span>
                                           
                                        </li>
                                        <li>
                                           
                                            <span className="tx-18 fw600">Discount</span>
                                            <span className="tx-18 fw600">-{multiCurrency(contextValues.cartSummary.discount)}</span>
                                        </li>
                                        <li>
                                            <span>Sales tax</span>
                                            <span>$0.00</span>
                                        </li>
                                    </ul>
                                    {contextValues.userToken ? <>
                                        <button className="btn btn-primary btn-full btn-lg" type="button" onClick={(e) => navigate("/address")}>Proceed to Checkout</button>
                                    </>:<>
                                    
                                    <button className="btn btn-primary btn-full btn-lg" type="button" onClick={(e) => setShow(true)}>Proceed to Checkout</button>
                                    </>}
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <div className='cartmodal-scroll'>
                    <div className='noimg'>
                        <img src='/img/empty-cart.webp' className='wd-250' alt='cart'></img>
                        <h5>Your Cart is Empty</h5>
                        <p>It looks like you haven't added any items to your cart yet.</p>
                        <a href='/' className='btn btn-primary-outline'>Continue Shopping</a>
                    </div>
                </div>
            }
        </section>
        {show && <LoginModal show={show} setShow={setShow} handleClose={handleClose} handleShow={handleShow}></LoginModal>}
        <Footer></Footer>
    </>)
}

export default Cart