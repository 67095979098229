import CartHeader from "../../Components/Header/cart_header"

const Checkout=()=>{
    return(<>
    <CartHeader></CartHeader>
    <p>Checkout</p>
    
    </>)
}

export default Checkout