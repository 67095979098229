import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { BrowserView, MobileView } from 'react-device-detect';
import { ApiService } from '../../Components/Services/apiservices';
import constant from '../../Components/Services/constant';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'


function HomeAddBanner() {
  const topslider = {
    slidesPerView: 1,
    navigation: false,
    loop: true,
    pagination: false,
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };

  const didMountRef = useRef(true)
  const [sliderWebData, setSliderWebData] = useState([])
  const [isLoading, setisLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState([])
  const navigate = useNavigate();


  useEffect(() => {
    if (didMountRef.current) {
      getBannerData()
    }

    didMountRef.current = false
  }, []);

  const getBannerData = () => {
    setisLoading(true)
    ApiService.fetchData("top-banner-list").then((res) => {
      if (res.status === "success") {
        setSliderWebData(res.resAddAfterCatData)
        setImageUrl(res.slider_image_path)
        setisLoading(false)
      }
      else {
        setisLoading(false)
      }
    }).catch(() => {
      setisLoading(true)
    })
  }
  const renderCarouselItem = (value, index, customeClass) => {
    if (value.slider_view === 2 && value.slider_video !== "") {
      if (value.slider_type === 1 && value.slider_url !== "") {
        return renderVideoLinkItem(value, index, customeClass);
      } else if (value.slider_type === 2 && value.cat_id) {
        return renderVideoCategoryItem(value, index, customeClass);
      } else if (value.slider_type === 3 && value.tag_id) {
        return renderVideoTagItem(value, index, customeClass);
      } else {
        return renderDefaultVideoItem(value, index, customeClass);
      }
    } else if (value.slider_type === 1 && value.slider_url !== "") {
      return renderLinkItem(value, index, customeClass);
    } else if (value.slider_type === 2 && value.cat_id) {
      return renderCategoryItem(value, index, customeClass);
    } else if (value.slider_type === 3 && value.tag_id) {
      return renderTagItem(value, index, customeClass);
    } else {
      return renderDefaultItem(value, index, customeClass);
    }
  };

  const renderVideoLinkItem = (value, index, customeClass) => {
    return (
      
      <div className={customeClass} key={index}>
        <a href={value.slider_url} >
          <video
            src={value.slider_video}
            autoPlay="autoplay"
            loop
            muted
            playsInline
            height={'200px'}
          ></video>
        </a>
      </div>
    );
  };
  const renderVideoCategoryItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index}>
        <a href={"/services/" + value.cat_slug} >
          <video
            src={value.slider_video}
            autoPlay="autoplay"
            loop
            muted
            playsInline
            height={'200px'}
          ></video>
        </a>
      </div>
    );
  };
  const renderVideoTagItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index}>
        <a href={"/services" + value.tag_slug} >
          <video
            src={value.slider_video}
            autoPlay="autoplay"
            loop
            muted
            playsInline
            height={'200px'}
          ></video>
        </a>
      </div>
    );
  };
  const renderDefaultVideoItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index}>
        <a href={'javascript:void(0)'} >
          <video
            src={value.slider_video}
            autoPlay="autoplay"
            loop
            muted
            playsInline
            height={'200px'}
          ></video>
        </a>
      </div>
    );
  };

  const renderLinkItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index} >
        <a href={value.slider_url} >
          <img
            src={value.slider_image ? value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };

  const renderCategoryItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index} >
        <a href={"/services/" + value.cat_slug} >
          <img
            src={value.slider_image ? value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };

  const renderTagItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index} >
        <a href={"/services" + value.tag_slug} >
          <img
            src={value.slider_image ? value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };

  const renderDefaultItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index}>
        <a href={"javascript:void(0)"} >
          <img
            src={value.slider_image ? value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };
  return (

    <>
      {isLoading ? <> {sliderWebData && sliderWebData.length > 0 ? (
        <section className='section-gap-medium'>
          <div className='container'>
            <div className='row'>
              {[...Array(3)].map((_ , index)=>{
                return(<>
                 <div className='col-4' key={index}>
              <div className={''}  >
                <a href={"javascript:void(0)"} >
                 <Skeleton width={'400px'} height={'250px'}></Skeleton>
                </a>
              </div>
              </div>
                </>)
              })}
             
             
            </div>
          </div>
        </section>
      ) : null}</> : <>

        {sliderWebData && sliderWebData.length > 0 ? (
          <section className='section-gap-small home-add-banner'>
            <div className='container'>
              {/* <div className='row'>
                <Swiper {...topslider}>
                  {sliderWebData
                    ? sliderWebData.map((value, index) => (
                      <SwiperSlide key={index}>{renderCarouselItem(value, index, 'heroSlider-slide')}</SwiperSlide>
                    ))
                    : null}

                </Swiper>
              </div> */}
              <Swiper
                spaceBetween={20}
                slidesPerView={3}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  320:{
                    slidesPerView:1,
                  },
                  575:{
                      slidesPerView:3,
                  },
                }}
              >
                <SwiperSlide>
                    <div className="add-block">
                        <a href="javascript:void(0)">
                            <picture className="add-block-img">
                                <img src="https://tamolo.in/projects/accordluxe/csadmin/public/img/uploads/media/9769521723611428.webp" alt="Slider 3" className="img-fluid"/>
                            </picture>
                        </a>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="add-block">
                        <a href="/services/bathroom-cleaning">
                            <picture className="add-block-img">
                                <img src="https://tamolo.in/projects/accordluxe/csadmin/public/img/uploads/media/2719541723546580.webp" alt="Slider 3" className="img-fluid"/>
                            </picture>
                        </a>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="add-block">
                        <a href="/services/bathroom-kitchen-cleaning">
                            <picture className="add-block-img">
                                <img src="https://tamolo.in/projects/accordluxe/csadmin/public/img/uploads/media/1846961723550197.webp" alt="Slider 3" className="img-fluid"/>
                            </picture>
                        </a>
                    </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </section>
        ) : null}


      </>}


    </>


  )
}

export default HomeAddBanner