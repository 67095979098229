import { useContext, useEffect, useState, useRef } from "react"
import DataContext from "../Elements/context"
import { Modal } from 'react-bootstrap'
import { ApiService } from "../Services/apiservices"
import { useNavigate } from "react-router-dom"
import sessionCartData from '../Elements/cart_session_data';
import Loader from "react-js-loader";
import { toast } from "react-toastify"
import multiCurrency from "../Elements/multiCurrency"
import { addToCart, addToCartSession, minusToCart, minusToCartSession, removeToCart, removeToCartSession } from '../Elements/add_to_cart';


const CartModal=()=>{
    const contextValues= useContext(DataContext)
    const navigate= useNavigate()
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [isLoading, setisLoading] = useState(false)
    const [variation, setvariation] = useState([])
    const didMountRef = useRef(true)

    const dataArray = sessionCartData();
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    useEffect(() => {
        if (didMountRef.current) {
            if (localStorage.getItem("USER_TOKEN")) {
                getCartSessionData();
            } else {
                contextValues.setCartSessionData(dataArray[1])
                contextValues.setCartCount(dataArray[1].length)
                contextValues.setCartSummary(dataArray[3])
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
           
        }
        didMountRef.current = false;
    }, []);

    const getCartSessionData = () => {
        const dataString = {
            coupon_session: localStorage.getItem("COUPON_SESSION"),
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
             
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                contextValues.setCartSessionData([])
                contextValues.setCartCount(0)
                contextValues.setCartSummary({})
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    }
    const removeToCartProcess = async (serviceData) => {
        setSpinnerLoading(false);
        if (localStorage.getItem("USER_TOKEN")) {
            const updateStatus = await removeToCartSession(serviceData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        } else {
            const updateStatus = await removeToCart(serviceData, contextValues);
            if (updateStatus) {
                setSpinnerLoading(false);
            } else {
                setSpinnerLoading(false);
            }
        }
    };
   
    const addToCartProcess = async (serviceData) => {
      
        if (parseFloat(serviceData.service_selling_price) > 0) {
            const productData = {
                service_id: Number(serviceData.service_id),
                service_name: serviceData.service_name,
                service_slug: serviceData.service_slug,
                service_image: serviceData?.service_image ,
                service_type: Number(serviceData.service_type),
                service_price: parseFloat(serviceData.service_price).toFixed(2),
                service_selling_price: parseFloat(serviceData.service_selling_price).toFixed(2),
                service_discount: serviceData.service_discount,
                service_variation: serviceData.service_variation,
                service_category_id: serviceData.service_category_id,
                selected_variation: serviceData.selected_variation,
                quantity: serviceData.quantity,
            };
            contextValues.setSpinnerCubLoader(serviceData.service_id)
            setvariation(serviceData.selected_variation)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                   
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                   
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };
    const minusToCartProcess = async (addservice) => {
        if (parseFloat(addservice.service_selling_price) > 0) {
            contextValues.setSpinnerCubLoader(addservice.service_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await minusToCartSession(addservice, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await minusToCart(addservice, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };
    const serviceModal = (data) => {
        contextValues.setProductData(data)
        setTimeout(() => {
            contextValues.setToggleServiceModal(!contextValues.toggleServiceModal)
        }, 100)
    }

   
    return(<>
     <Modal show={contextValues.toggleCartModal} onHide={(e) => cartModal()} size="xl">
                <button onClick={(e) => cartModal()}><i className="ri-close-line"></i></button>
                {contextValues.cartSessionData.length>0 ? (
                    <>
                   { contextValues.cartSessionData.map((value, index)=>{
                        return(<>
                           <div className='cartservice d-flex' key={index}>
                        <a href={'javascript:void(0)'} className="cartserviceRemove"  onClick={(e) => removeToCartProcess(value)}><i className="ri-delete-bin-6-line"></i></a>
                        <figure className='cartserviceMedia mr-15' onClick={()=>{serviceModal(value)}}>
                            <img src={value.service_image}></img>
                        </figure>
                        <div className='cartserviceDetails'>
                            <h2 className='title'><a href={'/service/' + value.service_slug}>{value.service_name}</a></h2>
                            {value.service_type == 1 && value.selected_variation && value.selected_variation.length > 0 ?
                                <>
                               {value.selected_variation.map((value, index) => {
                                        return (<div className='tx-gray-500 mb-10 tx-13' key={index}>{value.attr}: {value.terms}</div>)
                                    })}
                                   
                                </>
                                : null}
                            <div className="cartservice-footer">
                                <div className="cartservice-price">
                                    <ins className="new-price">{multiCurrency(value.service_selling_price)}</ins>
                                    {Number(value.service_price) > Number(value.service_selling_price) && (<del className="old-price">{multiCurrency(value.service_price)}</del>)}
                                </div>
                                <div className="cartserviceqty">
                                    <span onClick={(e) => minusToCartProcess(value)}><i className="ri-subtract-line"></i></span>
                                    <span>{contextValues.spinnerCubLoader == value.service_id && variation==value.selected_variation ? <div className='buttonLoader'><Loader type="spinner-default" bgColor={'#121212'} color={'#121212'} size={14} /> </div> : value.quantity}</span>
                                    <span onClick={(e) => addToCartProcess(value)}><i className="ri-add-line"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                        </>)
                     
                    })}  
                   
                  
                    <div className='cartmodal-footer'>
                    <div className='cartmodal-total'>
                        <div>
                            <p className="mb-0">Estimated Total </p>
                            <p className="mb-0 tx-12 tx-gray-500">Shipping & taxes calculated at checkout</p>
                        </div>
                        <span className="total-price">{multiCurrency(contextValues.cartSummary.itemTotal)}</span>
                    </div>
                    <button className="btn btn-lg btn-primary btn-full tx-uppercase mt-10" onClick={() => {cartModal() ; navigate('/cart') }}>View my shopping bag</button>
                </div>
                    
                    </>
                   
                ):   <div className='cartmodal-scroll'>
                <div className='noimg'>
                    <img src='/img/empty-cart.webp' className='wd-150' alt='cart'></img>
                    <h5>Your Cart is Empty</h5>
                    <p>It looks like you haven't added any items to your cart yet.</p>
                    <a href='/' className='btn btn-primary-outline'>Continue Shopping</a>
                </div>
            </div>}
            </Modal>
    
    </>)
}


export default CartModal