import React, { useEffect, useRef, useState } from "react";
import constant from "../../Components/Services/constant";
import { ApiService } from "../../Components/Services/apiservices";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import Skeleton from "react-loading-skeleton";
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
function HomeTestimonial() {
    const didMountRef = useRef(true);
    const [resTestimonialData, setResTestimonialData] = useState([]);
    const [testimonialImagePath, setTestimonialImagePath] = useState([]);
    const [loading, setLoading] = useState(false)
    const testimonialsCarouselOptions = {
        slidesPerView: 1,
        navigation: false,
        loop: true,
        pagination: false,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 1,
            },
        },
    };

    useEffect(() => {
        if (didMountRef.current) {
            getTestimonialData();
        }
        didMountRef.current = false;
    }, []);
    const getTestimonialData = () => {
        setLoading(true)
        ApiService.fetchData("featuredTestimonial").then((res) => {
            if (res.status == "success") {
                setResTestimonialData(res.data);
                setTestimonialImagePath(res.imgUrl);
                setLoading(false)
            }
            else {
                setLoading(false)
            }
        }).catch(() => {
            setLoading(false)
        });
    };
    return (
        <>
            {loading ? <> <section className='section-gap-medium'>
                <div className="container">
                    <div className="section-title text-center mb-3">
                        <h2><Skeleton width={'200px'} ></Skeleton></h2>
                    </div>
                    <div className="row ">
                        {[...Array(3)].map((_, index) => {
                            return (<>
                                <div className="col-lg-4" key={index}>
                                    <div class="tile">
                                        <picture>
                                            <Skeleton width={'400px'} height={'200px'}></Skeleton>
                                        </picture>
                                        <div className="tile-body">
                                            <h5><Skeleton width={'300px'} height={'20px'}></Skeleton></h5>
                                            <p><Skeleton width={'200px'} height={'20px'}></Skeleton></p>
                                            <div class="rating">
                                                <div className="d-flex">
                                                {[...Array(5)].map((item, index) => {
                                                    return (<>
                                                        <Skeleton width={'20px'} height={'20px'} className="me-3" key={index}></Skeleton>
                                                    </>)
                                                })}

                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </>)
                        })}

                    </div>
                </div>
            </section></> : <>
                {resTestimonialData && resTestimonialData.length > 0 ? (
                    <section className='section-gap-medium testimonial-section'>
                        <div className="container">
                            <div className="section-title text-center mb-40">
                                <h6 className="tx-primary">TESTIMONIALS</h6>
                                <h2>What Our Customer Have To Say</h2>
                                <p className="tx-gray">Our prices are simple and affordable which are easy on pocket<br/>in comparison with the high street prices </p>
                            </div>

                            <Swiper
                                spaceBetween={15}
                                modules={[Navigation, A11y,Pagination]}
                                
                                pagination={{ clickable: true }}
                                breakpoints={{
                                    640: {
                                        slidesPerView: 1,
                                       
                                    },
                                    768: {
                                        slidesPerView: 3,
                                        
                                    },
                                    1024: {
                                        slidesPerView: 3,
                                        
                                    },
                                }}
                            >
                                {
                                    resTestimonialData.map((value, index) => (
                                        <SwiperSlide>
                                            
                                            <div class="testimonial" key={index}>
                                                <div class="rating mb-2">
                                                    {[...Array(5)].map((_, starIndex) => (
                                                        <i key={starIndex}
                                                            className={starIndex < value.testimonial_rating ? "ri-star-fill"
                                                                : "ri-star-line"} ></i>
                                                    ))}
                                                </div>
                                                <div className="testimonial-desc mb-15" dangerouslySetInnerHTML={{ __html: value.testimonial_desc }}></div>
                                                <div className="testimonial-body d-flex align-items-center">
                                                    <picture class="testimonial-image">
                                                        <img src={value.testimonial_image != null ? testimonialImagePath + "/" + value.testimonial_image : constant.DEFAULT_IMAGE} alt="Testimonial"/>
                                                    </picture>
                                                    <div class="testimonial-text ms-3">
                                                        <h6 className="mb-0">{value.testimonial_name}</h6>
                                                        {/* <p className="mb-0"></p> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }

                            </Swiper>
                            <ul className="shape-group">
                                <li className="shape-1">
                                    <img src="../img/shape-10.png"/>
                                </li>
                                <li className="shape-2">
                                    <img src="../img/shape-25.png"/>
                                </li>
                                <li className="shape-3">
                                    <img src="../img/map-shape-3.png"/>
                                </li>
                            </ul>
                        </div>
                    </section>
                ) : null}
            </>}
        </>
    )
}

export default HomeTestimonial