import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import MobileHeader from '../../Components/Header/mobile_header'
import {BrowserView ,MobileView } from 'react-device-detect'
import Accountsidebar from './account_sidebar'

function Help() {
  return (
   <>
         <BrowserView>
        <Header />
        <section className='section-gap-medium'>
          <div className="container">
            <div className="row">
              <div className="col-3">
                <Accountsidebar></Accountsidebar>
              </div>
              <div className="col-9">
                <p>Help & Support</p>
              </div>
            </div>
          </div>
          </section>
        <Footer />
      </BrowserView>
      <MobileView>
        <MobileHeader PageName="Help" />
        <div>Help</div>
      </MobileView>


   </>

  )
}

export default Help