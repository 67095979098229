const HomeBottomBanner=()=>{
    return(<>
     <section className='section-gap-medium'>
                    <div className='container'>
                        <div className='row align-items-center'>
                            <div className='col-lg-5 tx-right'>
                                <div className='section-title mb-30'>
                                    <h2>Quality Service with<br></br>
                                        Free <span className='tx-primary'>Collection & Delivery</span></h2>
                                    <p className='tx-gray'>It is our goal to offer you the best possible laundry<br></br>
                                        and dry cleaning service available.</p>
                                </div>
                                <h2><a href='#'>1 (800) 765-43-21</a></h2>
                            </div>
                            <div className='col-lg-7'>
                                <img src='/img/deliverycar.png' className="img-fluid"></img>
                            </div>
                        </div>
                    </div>
                </section>
    
    
    
    </>)
}


export default HomeBottomBanner