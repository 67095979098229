import React from 'react'

function MobileHeader({ PageName }) {
  return (
    <>
    <header className="header mheader">
    <div className="header-left">
      <div className="header-title">
        <a href="/"><i className="ri-arrow-left-line ri-xl mr-10"></i></a>
        <h1>{PageName}</h1>
      </div>
    </div>
    </header>

    </>

  )
}

export default MobileHeader