import React, { useEffect, useRef, useState } from 'react';
import { ApiService } from '../../Components/Services/apiservices';
import { useParams } from 'react-router-dom';
import Header from '../../Components/Header';
import Footer from '../../Components/Footer';
import { BrowserView, MobileView } from 'react-device-detect';
import MobileHeader from '../../Components/Header/mobile_header';
import SubHeader from '../../Components/Header/sub_header';

function Pages() {
    const didMountRef = useRef(true);
    const { slug } = useParams();
    const [pageData, setPageData] = useState({});
    const [pageContent, setPageContent] = useState('');

    useEffect(() => {
        if (didMountRef.current) {
            didMountRef.current = false;
            const getPageData = {
                slug: slug,
            };

            ApiService.postData("page-data", getPageData)
                .then((res) => {
                    if (res.status === "success") {
                        setPageData(res.pagedata);
                        setPageContent(res.pagedata.page_content || '');
                    } else {

                        console.error('Error fetching page data:', res.message);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching page data:', error);
                });
        }
    }, [slug]);

    return (
        <>
        <BrowserView>
        <Header />
        <SubHeader pageName={pageData.page_name}></SubHeader>
        <section className='section-gap-medium home-add-banner' dangerouslySetInnerHTML={{ __html: pageContent }}></section>
            <Footer />

        </BrowserView>
        <MobileView>
       <MobileHeader PageName={pageData.page_name} />
       <section className='section-gap-medium home-add-banner' dangerouslySetInnerHTML={{ __html: pageContent }}></section>
            <Footer />
        </MobileView>
    
        </>
    );
}

export default Pages;
