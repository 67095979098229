import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'remixicon/fonts/remixicon.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Containers/Home';
import Pages from './Containers/Pages';
import ContactUs from './Containers/ContactUs';
import Address from './Containers/Account/address';
import Order from './Containers/Account/order';
import Profile from './Containers/Account/profile';
import Services from './Containers/Services';
import Pricing from './Containers/Pricing';
import Bookings from './Containers/Bookings';
import Help from './Containers/Account/help';
import AccountOverview from './Containers/Account/account_overview';
import { ToastContainer, toast } from 'react-toastify';
import 'react-loading-skeleton/dist/skeleton.css'
import AllServices from './Containers/AllServices';
import Cart from './Containers/Cart';
import Checkout from './Containers/Cart/checkout';
import CheckoutAddress from './Containers/Cart/address';

function App() {
  return (
    <div className="App">
      <ToastContainer
          position="bottom-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      <Router>
        <Routes>
        <Route exact path="/" activeClassName="active" element={<Home />} />
        <Route exact path="/contact-us" activeClassName="active" element={<ContactUs />} />
        <Route exact path="/account/account-overview" activeClassName="active" element={<AccountOverview />} />
        <Route exact path="/account/address" activeClassName="active" element={<Address />} />
        <Route exact path="/account/order" activeClassName="active" element={<Order />} />
        <Route exact path="/account/profile" activeClassName="active" element={<Profile />} />
        <Route exact path="/account/help" activeClassName="active" element={<Help />} />


        <Route exact path="/services/:slug" activeClassName="active" element={<Services />} />

        <Route exact path="/pricing" activeClassName="active" element={<Pricing />} />
        <Route exact path="/bookings" activeClassName="active" element={<Bookings />} />


        <Route exact path="/services" activeClassName="active" element={<AllServices />} />
        <Route exact path="/address" activeClassName="active" element={<CheckoutAddress />} />

        <Route exact path=":slug" activeClassName="active" element={<Pages />} />

        <Route exact path="/cart" activeClassName="active" element={<Cart />} />
        <Route exact path="/checkout" activeClassName="active" element={<Checkout />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
