import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { BrowserView, MobileView } from 'react-device-detect';
import { ApiService } from '../../Components/Services/apiservices';
import constant from '../../Components/Services/constant';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
function HomeTopBanner() {

  const topslider = {
    slidesPerView: 1,
    navigation: false,
    loop: true,
    pagination: false,
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    modules: [Autoplay],
    breakpoints: {
      0: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 1,
      },
      992: {
        slidesPerView: 1,
      },
    },
  };


  const didMountRef = useRef(true)
  const [sliderWebData, setSliderWebData] = useState([])
  const [sliderMobileData, setSliderMobileData] = useState([])
  const [imageUrl, setImageUrl] = useState([])
  const [isLoading, setisLoading] = useState(false)

  const navigate = useNavigate();
  useEffect(() => {
    if (didMountRef.current) {
      getBannerData()
    }

    didMountRef.current = false
  }, []);

  const getBannerData = () => {
    setisLoading(true)
    ApiService.fetchData("top-banner-list").then((res) => {
      if (res.status === "success") {
        setSliderWebData(res.resTopBannerData)
        setSliderMobileData(res.resMobileBannerData)
        setImageUrl(res.slider_image_path)
        setisLoading(false)
      } else {
        setisLoading(false)
      }
    }).catch(() => {
      setisLoading(false)
    })
  }

  const renderCarouselItem = (value, index, customeClass) => {
    if (value.slider_view === 2 && value.slider_video !== "") {
      if (value.slider_type === 1 && value.slider_url !== "") {
        return renderVideoLinkItem(value, index, customeClass);
      } else if (value.slider_type === 2 && value.cat_id) {
        return renderVideoCategoryItem(value, index, customeClass);
      } else if (value.slider_type === 3 && value.tag_id) {
        return renderVideoTagItem(value, index, customeClass);
      } else {
        return renderDefaultVideoItem(value, index, customeClass);
      }
    } else if (value.slider_type === 1 && value.slider_url !== "") {
      return renderLinkItem(value, index, customeClass);
    } else if (value.slider_type === 2 && value.cat_id) {
      return renderCategoryItem(value, index, customeClass);
    } else if (value.slider_type === 3 && value.tag_id) {
      return renderTagItem(value, index, customeClass);
    } else {
      return renderDefaultItem(value, index, customeClass);
    }
  };

  const renderVideoLinkItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index}>
        <a href={value.slider_url} >
          <video
            src={value.slider_video}
            autoPlay="autoplay"
            loop
            muted
            playsInline
            height={'200px'}
          ></video>
        </a>
      </div>
    );
  };
  const renderVideoCategoryItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index}>
        <a href={"/services/" + value.cat_slug} >
          <video
            src={value.slider_video}
            autoPlay="autoplay"
            loop
            muted
            playsInline
            height={'200px'}
          ></video>
        </a>
      </div>
    );
  };
  const renderVideoTagItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index}>
        <a href={"/services" + value.tag_slug} >
          <video
            src={value.slider_video}
            autoPlay="autoplay"
            loop
            muted
            playsInline
            height={'200px'}
          ></video>
        </a>
      </div>
    );
  };
  const renderDefaultVideoItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index}>
        <a href={'javascript:void(0)'} >
          <video
            src={value.slider_video}
            autoPlay="autoplay"
            loop
            muted
            playsInline
            height={'200px'}
          ></video>
        </a>
      </div>
    );
  };

  const renderLinkItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index} >
        <a href={value.slider_url} >
          <img
            src={value.slider_image ? value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };

  const renderCategoryItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index} >
        <a href={"/services/" + value.cat_slug} >
          <img
            src={value.slider_image ? value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };

  const renderTagItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index} >
        <a href={"/services" + value.tag_slug} >
          <img
            src={value.slider_image ? value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };

  const renderDefaultItem = (value, index, customeClass) => {
    return (
      <div className={customeClass} key={index}>
        <a href={"javascript:void(0)"} >
          <img
            src={value.slider_image ? value.slider_image : constant.DEFAULT_IMAGE}
            alt={value.slider_name}
          />
        </a>
      </div>
    );
  };


  return (
    <>
      <BrowserView>
        {isLoading ? <>
          <div className='heroSlider'>
            <div className={''}  >
              <a href={"javascript:void(0)"} >
                <Skeleton width={"100%"} height={'850px'}></Skeleton>
              </a>
            </div>

          </div>


        </> : <> {sliderWebData && sliderWebData.length > 0 && (
          <div className='heroSlider'>
            <Swiper {...topslider}>
              {sliderWebData
                ? sliderWebData.map((value, index) => (
                  <SwiperSlide key={index}>{renderCarouselItem(value, index, 'heroSlider-slide')}</SwiperSlide>
                ))
                : null}
            </Swiper>

          </div>
        )}</>}


      </BrowserView>
      <MobileView>
        {isLoading ? <>
          <div className='heroSlider'>
            <div className={''}  >
              <a href={"javascript:void(0)"} >
                <Skeleton width={'100%'} height={'700px'}></Skeleton>
              </a>
            </div>
          </div>



        </> : <>  {sliderMobileData && sliderMobileData.length > 0 ? (
          <div className='heroSlider'>
            <Swiper {...topslider}>
              {sliderMobileData
                && sliderMobileData.map((value, index) => (
                  <SwiperSlide key={index}>{renderCarouselItem(value, index, 'heroSlider-slide')}</SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        ) : null}</>}

      </MobileView>
    </>
  )
}

export default HomeTopBanner 