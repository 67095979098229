import React, { useEffect, useRef, useState,useContext } from 'react'
import { BrowserView, MobileView } from 'react-device-detect'
import { ApiService } from '../Services/apiservices'
import DataContext from '../Elements/context'
import LoginModal from '../Modals/login_modal'
import Skeleton from 'react-loading-skeleton'

function Footer() {

    const didMountRef = useRef(true)
    const [settingData, setSettingData] = useState({})
    const [settingImagePath, setSettingImagePath] = useState({})
    const [footerData, setfooterData] = useState({})
    const contextValues = useContext(DataContext);
    const [isLoading, setisLoading] = useState(false)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (didMountRef.current) {
            getThemAdminData()
            getfooterData()
        }
        didMountRef.current = false
    })

    const getThemAdminData = () => {
       
        ApiService.fetchData("setting-data").then((res) => {
            if (res.status === "success") {
                setSettingData(res.sitesettings)
                setSettingImagePath(res.setting_image_path)

            }
        })
    }

    const getfooterData = () => {
        setisLoading(true)
        ApiService.fetchData("footer-data").then((res) => {
            if (res.status === "success") {
                setfooterData(res.footerData)
                setisLoading(false)
            } else {
                console.error("Failed to fetch footer data:", res.message);
                setisLoading(false)
            }
        }).catch(()=>{
            setisLoading(false)
        })
    }
    const loginModal = () => {
        setShow(true)
    }
    return (
        <>
            <BrowserView>
            {isLoading? <><footer className='footer'>
                    <div className='footerMiddle'>
                        <div className="container">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <a href='javascript:void(0)' className='footerLogo mb-20'>
                                       <Skeleton width={'200px'} height={'100px'}></Skeleton>
                                    </a>
                                    <p > <Skeleton width={'300px'}></Skeleton></p>
                                    <p > <Skeleton width={'300px'}></Skeleton></p>
                                    <p > <Skeleton width={'300px'}></Skeleton></p>
                                    <ul className="footerContact">
                                        <li>
                                            <div className="fcbix">
                                                <div className="fcbix-icon"><Skeleton width={'20px'} ></Skeleton></div>
                                                <div className="fcbix-text">
                                                    <p className="mb-1 tx-gray tx-12"> <Skeleton width={'100px'} ></Skeleton></p>
                                                    <a href={`javascript:void(0)`}>
                                                        <h6><Skeleton width={'100px'} ></Skeleton></h6>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                       
                                    </ul>
                                </div>
                                <div className='col-lg-2'>
                                    <h5 className="footer-title"><Skeleton width={'150px'} ></Skeleton></h5>
                                    <div >
                                    <Skeleton width={'100px'} ></Skeleton>
                                    </div>
                                    <div >
                                    <Skeleton width={'100px'} ></Skeleton>
                                    </div>
                                    <div >
                                    <Skeleton width={'100px'} ></Skeleton>
                                    </div>
                                    <div >
                                    <Skeleton width={'100px'} ></Skeleton>
                                    </div>

                                </div>

                                <div className='col-lg-2'>
                                    <h5 className="footer-title"><Skeleton width={'150px'} ></Skeleton></h5>
                                    <div >
                                    <Skeleton width={'100px'} ></Skeleton>
                                    </div>
                                    <div >
                                    <Skeleton width={'100px'} ></Skeleton>
                                    </div>
                                    <div >
                                    <Skeleton width={'100px'} ></Skeleton>
                                    </div>
                                    <div >
                                    <Skeleton width={'100px'} ></Skeleton>
                                    </div>

                                </div>
                                <div className='col-lg-4'>
                                    <h2 className='footer-title'><Skeleton width={'150px'} ></Skeleton></h2>
                                    <div className="footer-social mt-20">
                                        <ul>
                                            <li>  <Skeleton width={'50px'} height={'50px'}></Skeleton></li>
                                            <li>  <Skeleton width={'50px'} height={'50px'}></Skeleton></li>
                                            <li>  <Skeleton width={'50px'} height={'50px'}></Skeleton></li>
                                            <li>  <Skeleton width={'50px'} height={'50px'}></Skeleton></li>

                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footerBottom'></div>
                </footer></>:<><footer className='footer'>
                    <div className='footerMiddle'>
                        <div className="container">
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <a href='/' className='footerLogo mb-20'>
                                        <img src={settingData.logo ? settingImagePath + settingData.logo : '/img/logo.png'} alt='logo-image'></img>
                                    </a>
                                    <p dangerouslySetInnerHTML={{ __html: footerData.footer_desc1 }}></p>
                                    <ul className="footerContact">
                                        <li>
                                            <div className="fcbix">
                                                <div className="fcbix-icon"><i className="ri-phone-line"></i></div>
                                                <div className="fcbix-text">
                                                    <p className="mb-1 tx-gray tx-12">24/7 support no.</p>
                                                    <a href={`tel:${settingData.admin_mobile}`}>
                                                        <h6>{settingData.admin_mobile}</h6>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="fcbix">
                                                <div className="fcbix-icon"><i className="ri-mail-send-line"></i></div>
                                                <div className="fcbix-text">
                                                    <p className="mb-1 tx-gray tx-12">Need help with your order?</p>
                                                    <a href={`mailto:${settingData.admin_email}`}>
                                                        <h6>{settingData.admin_email}</h6>
                                                    </a>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className='col-lg-2 col-md-4 mt-md-3 mb-sm-3'>
                                    <h5 className="footer-title">{footerData.footer_title2}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: footerData.footer_desc2 }}>
                                    </div>
                                </div>

                                <div className='col-lg-2 col-md-4 mt-md-3 mb-sm-3'>
                                    <h5 className="footer-title">{footerData.footer_title3}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: footerData.footer_desc3 }}>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 mt-md-3 mb-sm-3'>
                                    <h2 className='footer-title'>Follow us on</h2>
                                    <div className="footer-social mt-20">
                                        <ul>

                                            {settingData.facebook_url && (
                                                <li><a href={settingData.facebook_url} target="new"><i className="ri-facebook-fill"></i></a></li>
                                            )}
                                            {settingData.instagram_url && (
                                                <li><a href={settingData.instagram_url} target="new"><i className="ri-instagram-fill"></i></a></li>
                                            )}
                                            {settingData.twitter_url && (
                                                <li><a href={settingData.twitter_url} target="new"><i className="ri-twitter-fill"></i></a></li>
                                            )}
                                            {settingData.youtube_url && (
                                                <li><a href={settingData.youtube_url} target="new"><i className="ri-youtube-fill"></i></a></li>
                                            )}

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='footerBottom'></div>
                </footer></>}
                

            </BrowserView>

            <MobileView>


            </MobileView>
            <div className='appBottomMenu'>
                <a href='/' className='item active'>
                    <div className='itemBox'>
                        <i className="ri-home-smile-line"></i>
                        <span>Home</span>
                    </div>
                </a>
                <a href='/services' className='item'>
                    <div className='itemBox'>
                        <i className="ri-stack-line"></i>
                        <span>Services</span>
                    </div>
                </a>
                {contextValues.userToken ? (
                    <>
                        <a href='/bookings' className='item'>
                            <div className='itemBox'>
                                <i className="ri-file-list-3-line"></i>
                                <span>Bookings</span>
                            </div>
                        </a>
                    </>
                ) : (
                    <>
                        <a 
                         href="javascript:void(0)"
                         onClick={loginModal}
                         className='item'>
                            <div className='itemBox'>
                                <i className="ri-file-list-3-line"></i>
                                <span>Bookings</span>
                            </div>
                        </a>

                    </>
                )}


                <a href='/account/help' className='item'>
                    <div className='itemBox'>
                        <i className="ri-question-line"></i>
                        <span>Help</span>
                    </div>
                </a>
                {contextValues.userToken ? (
                    <>
                <a href='/account/account-overview' className='item'>
                    <div className='itemBox'>
                        <i className="ri-user-line"></i>
                        <span>Account</span>
                    </div>
                </a>
                </>
                ) : (
                    <>
                <a href="javascript:void(0)" 
                 onClick={loginModal}
                 className='item'>
                    <div className='itemBox'>
                        <i className="ri-user-line"></i>
                        <span>Account</span>
                    </div>
                </a>
                </>
            )}
            </div>
            {show && <LoginModal show={show} setShow={setShow} handleClose={handleClose} handleShow={handleShow}></LoginModal>}
        </>

    )
}

export default Footer
