import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import DataContext from "../Elements/context";
import { Modal, Toast } from 'react-bootstrap';
import { ApiService } from '../Services/apiservices';
import constant from '../Services/constant';
import Loader from "react-js-loader";
import 'react-toastify/dist/ReactToastify.css';
import { Alert, Button } from 'react-bootstrap';
function LocationModal() {

    const contextValues = useContext(DataContext);
    const [cityList, setCityList] = useState([])
    const [error, setError] = useState('');
    const [cityQuery, setCityQuery] = useState('');
    const [searchCity, setSearchCity] = useState([]);
    const [cityservicablestatus, setcityservicablestatus] = useState(false)
    const [isLoadingcities, setisLoadingcities] = useState(false)
    const [isSpinnerloading, setisSpinnerloading] = useState(false);
    const [showfeaturedCity, setshowfeaturedCity] = useState(true);
    const [imageUrl, setimageUrl] = useState('')
    const [selectedIndex, setselectedIndex] = useState('')
    const didMountRef = useRef(true)


    useEffect(() => {
        if (didMountRef.current) {
            console.log(contextValues.currentPosition, ' contextValues.currentPosition')
            if (contextValues.currentPosition.lat !== '' && contextValues.currentPosition.lng !== '') {
                onDefaultSelectLocation()
            }
            else {
                getCityData()
            }
        }
        didMountRef.current = false

    })


    const getCityData = (currentcityselected) => {
        setisLoadingcities(true)
        ApiService.fetchData("citylist").then((res) => {
            if (res.status === "success") {
                setisLoadingcities(false)
                setCityList(res.data)
                setimageUrl(res.imgUrl)
                const selectedCity = JSON.parse(localStorage.getItem('selectedCity'))
                if (!selectedCity) {
                    if (currentcityselected) {
                        const index = res.data.findIndex(city => city.cities_id === currentcityselected.cities_id);
                        if (index !== -1) {
                            setshowfeaturedCity(true)
                            setselectedIndex(index)
                        }
                        else {
                            setshowfeaturedCity(false)
                            setselectedIndex('')
                        }
                    } else {
                        const index = res.data.findIndex(city => city.cities_id === 3378);
                        if (index !== -1) {


                            setselectedIndex(index)
                        }
                    }

                }
                else {

                    const index = res.data.findIndex(city => city.cities_id === selectedCity.cities_id);

                    if (index !== -1) {
                        console.log('helloooo')
                        setselectedIndex(index)
                    }
                    else {
                        
                        setselectedIndex('')
                    }
                }

            }
            else {
                setisLoadingcities(false)

            }

        }).catch((error) => {
            setisLoadingcities(false)
            // setisSpinnerloading(false)
        })
    }
    const locationModal = () => {
        contextValues.setToggleLocationModal(!contextValues.toggleLocationModal)
    }

    const onCloseBttn = (e) => {
        e.preventDefault()
        const selectedCity = JSON.parse(localStorage.getItem('selectedCity'))
        const defaultSelectCity = {
            cities_name: 'Jaipur',
            cities_id: 3378
        }
        if (!selectedCity) {
            checkServicable(defaultSelectCity)
        }
        else {
            locationModal()
        }
    }

    // current location

    const onCurrentLocation = () => {
        setError('')

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log(position.coords.latitude, position.coords.longitude, 'location moaal')
                    const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyAE7oxhbgUxiIzikhCeN6TB6UUe1frQnZs`;

                    fetch(geocodeUrl)
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.results && data.results.length > 0) {
                                const addressComponents = data.results[0].address_components;
                                const cityComponent = addressComponents.find(component =>
                                    component.types.includes("locality")
                                );
                                const stateComponent = addressComponents.find(component =>
                                    component.types.includes("administrative_area_level_1")
                                );
                                const countryComponent = addressComponents.find(component =>
                                    component.types.includes("country")
                                );
                                if (cityComponent && stateComponent && countryComponent) {
                                    const cityExistData = {
                                        cities_name: cityComponent.long_name,
                                        state_name: stateComponent.long_name,
                                        country_name: countryComponent.long_name
                                    }

                                    checkCityExist(cityExistData)
                                    setError(''); // Clear any previous errors
                                } else {
                                    setError('City not found')

                                }
                            } else {
                                setError('No city result found for the given location.')

                            }
                        })
                        .catch((error) => {
                            setError('Error fetching geolocation : ' + error.message)

                        });
                },
                (error) => {
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            setError('Permission denied. Please allow location access.')

                            break;
                        case error.POSITION_UNAVAILABLE:
                            setError('Position unavailable. Please try again later.')

                            break;
                        case error.TIMEOUT:
                            setError('Request timed out. Please try again.')

                            break;
                        default:
                            setError('Not able to find your location. Please try again later')
                            break;
                    }
                }
            );
        } else {
            setError('Geolocation is not supported by this browser.');
        }
    };

    const onDefaultSelectLocation = () => {
        setisSpinnerloading(true)
        setshowfeaturedCity(false)
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${contextValues.currentPosition.lat},${contextValues.currentPosition.lng}&key=AIzaSyAE7oxhbgUxiIzikhCeN6TB6UUe1frQnZs`;

        fetch(geocodeUrl)
            .then((response) => response.json())
            .then((data) => {
                if (data.results && data.results.length > 0) {
                    const addressComponents = data.results[0].address_components;
                    const cityComponent = addressComponents.find(component =>
                        component.types.includes("locality")
                    );
                    const stateComponent = addressComponents.find(component =>
                        component.types.includes("administrative_area_level_1")
                    );
                    const countryComponent = addressComponents.find(component =>
                        component.types.includes("country")
                    );
                    if (cityComponent && stateComponent && countryComponent) {
                        const cityExistData = {
                            cities_name: cityComponent.long_name,
                            state_name: stateComponent.long_name,
                            country_name: countryComponent.long_name
                        }
                        setisSpinnerloading(false)
                        checkCityExist(cityExistData, 'defaultlocationpermission')
                        setError(''); // Clear any previous errors
                    } else {
                        setisSpinnerloading(false)
                        setcityservicablestatus(true)
                        // setError('City not found Please try again ')

                    }
                } else {
                    setisSpinnerloading(false)
                    setcityservicablestatus(true)
                    // setError('No city result found for the given location.Please try again')

                }
            })
            .catch((error) => {
                setisSpinnerloading(false)
                setcityservicablestatus(true)
                // setError('Error fetching geolocation : ' + error.message)

            });
    }

    const checkCityExist = (cityexistdata, type) => {
        setisSpinnerloading(true)
        const dataString = {
            cities_name: cityexistdata.cities_name,
            state_name: cityexistdata.state_name,
            country_name: cityexistdata.country_name
        }
        ApiService.postData('existCityData', dataString).then((res) => {
            if (res.status == 'success') {

                if (type == 'defaultlocationpermission') {
                    getCityData(res.data)
                    setTimeout(() => {
                        checkServicable(res.data)
                    }, 500)
                }
                else {
                    checkServicable(res.data)
                }

            }
            else {
                setcityservicablestatus(true)
                // setError('Sorry! Service is not reached at the provided location')
                setisSpinnerloading(false)
            }
        })
    }
    // for autocomplete location

    const handleSearchCity = (e) => {
        setError('')
        setCityQuery(e.target.value)
        if (e.target.value.length > 2) {
            const dataString = {
                cities_name: e.target.value
            }
            ApiService.postData('getSearchedCityData', dataString).then((res) => {
                if (res.status == 'success') {
                    setSearchCity(res.data)
                }
            }).catch(() => { })
        }
        else {
            setSearchCity([])
        }
    }


    const checkServicable = (servicablecity) => {
        setisSpinnerloading(true)
        const dataString = {
            cities_id: servicablecity?.cities_id
        }
        ApiService.postData('cityservicable', dataString).then((res) => {
            if (res.status == 'success') {
                setisSpinnerloading(false)
                if (res.message == 'servicable') {
                    localStorage.setItem('selectedCity', JSON.stringify({
                        cities_name: servicablecity?.cities_name,
                        cities_id: servicablecity?.cities_id
                    }));
                    contextValues.setselectedCity(servicablecity?.cities_name)
                    setisSpinnerloading(false)
                    locationModal()


                }
                else if (res.message == 'not-servicable') {

                    localStorage.removeItem('selectedCity')
                    contextValues.setselectedCity('Jaipur')
                    setcityservicablestatus(true)
                    setTimeout(() => {
                        setisSpinnerloading(false)
                        setCityQuery('')
                    }, 500)
                }
            }
            else {
                setError(res.message)
                setTimeout(() => {
                    setisSpinnerloading(false)

                }, 1000)

            }
        }).catch(() => {
            setTimeout(() => {
                setisSpinnerloading(false)

            }, 1000)

        })
    }

    const handleSelect = (cityData) => {
        setError('')

        const plainTextCity = `${cityData.cities_name}, ${cityData.state_name}, ${cityData.country_name}`;
        setCityQuery(plainTextCity)
        checkServicable(cityData)
        setSearchCity([])

    };



    // on select featured city click
    const onClickfeaturedcities = (citydata, index) => {
        setselectedIndex(index)
        setError('')
        checkServicable(citydata)
    }

    const ChangeLocation = () => {
        setcityservicablestatus(false)
        setisSpinnerloading(false)
    }

    return (
        <Modal className='locationModal' show={contextValues.toggleLocationModal} onHide={(e) => locationModal()} backdrop="static">
            <button className='closeButton' onClick={(e) => onCloseBttn(e)}><i className="ri-close-line" ></i></button>
            <div className='locationModalContant'>
                <h5>Select Your City</h5>
                {error && (<Alert variant="danger">{error}</Alert>)}
                {isSpinnerloading ? <Loader type="spinner-default" bgColor={'orange'} color={'orange'} size={40} /> : <>

                    {!cityservicablestatus && (
                        <>
                            <div className='citySearch mt-3 mb-3'
                            >
                                <input type='text' placeholder='Search for your city'
                                    value={cityQuery}
                                    onChange={handleSearchCity}
                                ></input>
                                <div className='citySearchIcon'>
                                    <i class="ri-search-line"></i>
                                </div>
                                {searchCity && searchCity.length > 0 && <ul>
                                    {searchCity.map((item, index) => {
                                        return (<>
                                            <li key={index} onClick={() => { handleSelect(item) }}>
                                                <strong>{item.cities_name}</strong> <small>{item.state_name} , {item.country_name}</small>
                                            </li>
                                        </>)
                                    })}
                                </ul>}
                            </div>
                            <div className='currentlocation mb-3' onClick={() => { onCurrentLocation() }}>
                                <i class="ri-crosshair-2-line ri-lg"></i>Use current location
                            </div>
                            {isLoadingcities ? <>

                                <Loader type="spinner-default" bgColor={'orange'} color={'orange'} size={40} />

                            </> : <>
                                {showfeaturedCity && cityList && cityList.length > 0 && (
                                    <div className='cityGrid-warp'>
                                        {cityList.map((item, index) => {
                                            return (<>
                                                <a href='javascript:void(0)' className={`cityGrid ${selectedIndex == index ? 'active' : ''}`} key={index} onClick={() => { onClickfeaturedcities(item, index) }}>
                                                    <div className='cityGridIcon'>
                                                        <img src={item.cities_image ? imageUrl + item.cities_image : constant.DEFAULT_IMAGE}></img>
                                                    </div>
                                                    <h6>{item.cities_name}</h6>
                                                </a>

                                            </>)
                                        })}
                                    </div>
                                )}

                            </>}

                        </>
                    )}

                    {cityservicablestatus && (
                        <div className='text-center'>
                            <div style={{ width: '200px', height: '200px', margin: 'auto' }}>
                                <img src='/img/unservicable.avif' style={{ width: '100%', height: '100%' }} ></img>
                            </div>
                            <h6>Looks like we aren't here yet.</h6>
                            <p>We will come here soon! Please try another location for now</p>
                            <button className="btn btn-primary btn-half btn-lg mb-4" onClick={(e) => ChangeLocation()}>Change</button>
                           
                        </div>

                    )}



                </>}



            </div>
        </Modal>
    );
}

export default LocationModal;
