const SubHeader=({pageName})=>{
    return(<>
    
    <div className="sub-header text-center py-5">
        <div className="container">
            <h1>{pageName}</h1>
            <nav aria-label="breadcrumb" className="sub-header-breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/" className="tx-theme">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">{pageName}</li>
                </ol>
            </nav>
        </div>
    </div>
    
    
    </>)
}

export default SubHeader