import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'

function Pricing() {

    const didMountRef = useRef(true)
    const [pageData,setPageData] = useState({})
    useEffect(() => {
        if (didMountRef.current) {
            getPageSection()
        }
        didMountRef.current = false
    })

    const getPageSection = () => {
        const dataString = {
            slug: "pricing",
        }
        ApiService.postData("page-data", dataString).then((res) => {
            setPageData(res.pagedata)
        })
    }

    return (
        <>
            <Header />
            <h3>Pricing</h3>
            <Footer />
        </>

    )
}

export default Pricing