import React, { useContext, useState, useEffect, useRef } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import Accountsidebar from './account_sidebar'
import { BrowserView } from 'react-device-detect'
import Skeleton from 'react-loading-skeleton'
import DataContext from '../../Components/Elements/context'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ApiService } from '../../Components/Services/apiservices'
import AddressModal from '../../Components/Modals/address_modal'



function Address() {
    const contextValues = useContext(DataContext)
    const [isLoading, setisLoading] = useState(false)

    const [userAddressDetails, setUserAddressDetails] = useState({
        ua_id: 0,
        ua_name: "",
        ua_email: "",
        ua_mobile: "",
        ua_pincode: "",
        ua_house_no: "",
        ua_area: "",
        ua_state_name: "",
        ua_city_name: "",
        ua_address_type: "home",
        ua_address_type_other: "",
        ua_state_id: "",
        ua_city_id: "",
        ua_default_address: "",
        ua_country_id: 101,
    });
    const didMountRef = useRef(true);
    const navigate = useNavigate()
    const [userAddressList, setuserAddressList] = useState([])
    useEffect(() => {
        if (didMountRef.current) {
            getuserAddress()
        }
        didMountRef.current = false;
    }, []);
    const getuserAddress = () => {
        setisLoading(true)
        ApiService.fetchData('get-user-address').then((res) => {
            if (res.status == 'success') {
                setuserAddressList(res.resUserAddress)
                setisLoading(false)
            }
            else if (res.status == 'error' && res.message == 'Session expired') {
                setisLoading(false)
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
            }
        }).catch(() => {
            setisLoading(false)
        })

    }

    const addressModal = () => {
        contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)
    }
    const editAddress = (value) => {

        setUserAddressDetails({
            ua_id: value.ua_id,
            ua_name: value.ua_name,
            ua_email: value.ua_email,
            ua_mobile: value.ua_mobile,
            ua_pincode: value.ua_pincode,
            ua_house_no: value.ua_house_no,
            ua_area: value.ua_area,
            ua_state_name: value.ua_state_name,
            ua_city_name: value.ua_city_name,
            ua_address_type: value.ua_address_type,
            ua_address_type_other: value.ua_address_type_other,
            ua_state_id: value.ua_state_id,
            ua_city_id: value.ua_city_id,
            ua_default_address: "",
            ua_country_id: 101,
        })
        contextValues.setToggleAddressModal(!contextValues.toggleAddressModal)

    }

    const deleteaddress = (value) => {
        if (window.confirm("Are you sure about delete the address ?")) {
            const dataString = {
                addrid: value,
            };
            ApiService.postData("removeAddress", dataString).then((res) => {
                if (res.status == "success") {
                    getuserAddress();
                }
                else {

                    toast.error(res?.message)
                }
            });
        } else {

        }
    };
    return (
        <>
            <BrowserView>
                <Header />
                <section className='section-gap-medium'>
                    <div className="container">
                        <div className="row">
                            <div className="col-3">
                                <Accountsidebar></Accountsidebar>
                            </div>

                            <div className="col-lg-9">
                                <div className="section-title">
                                    <h2>Addresses</h2>
                                </div>
                                <div className="row g-3">
                                    {isLoading ? <>
                                        {[...Array(4)].map((item, index) => {
                                            return (<>
                                                <div className="col-lg-6" key={index}>
                                                    <div className="addressbox mb-2">
                                                        <div className="addressbox-body">
                                                            <h6 className="mb-1 fw400 tx-uppercase"><Skeleton width={'200px'} ></Skeleton></h6>
                                                            <p className="mb-1"><Skeleton width={'100px'} ></Skeleton></p>
                                                            <p className="mb-0"><Skeleton width={'100px'} ></Skeleton></p>
                                                        </div>
                                                        <div className="addressbox-footer"><button className="btn  btn-small me-3" ><Skeleton width={'100px'} height={'50px'}></Skeleton></button><button className="btn  btn-small"><Skeleton width={'100px'} height={'50px'}></Skeleton></button></div>
                                                    </div>
                                                </div>
                                            </>)
                                        })}
                                    </> : userAddressList && userAddressList?.length > 0 ? <>
                                        {userAddressList?.map((address, index) => {
                                            return (<>
                                                <div className="col-lg-6" key={index}>
                                                    <div className="addressbox mb-2">
                                                        <div className="addressbox-body">
                                                            <h6 className="mb-1 fw400 tx-uppercase">{address?.ua_name ? address?.ua_name : ''} {''}{address?.ua_default_address == 1 ? '(Default)' : ''}</h6>
                                                            <p className="mb-1">{address?.ua_house_no}, {address?.ua_area}, {address?.ua_city_name},{address?.ua_state_name} {address?.ua_pincode}</p>
                                                            {address?.ua_email ? <p className="mb-0">Email Address: <span className="font-third">{address?.ua_email}</span></p> : ''}
                                                        </div>
                                                        <div className="addressbox-footer"><button className="btn btn-primary-outline btn-small me-3" onClick={() => { editAddress(address) }}>Edit</button><button className="btn btn-primary-outline btn-small" onClick={() => { deleteaddress(address.ua_id) }}>Delete</button></div>
                                                    </div>
                                                </div>
                                            </>)
                                        })}
                                        <p><a className="btn btn-underline-primary p-0" href="javascript:void(0)" onClick={(e) => { addressModal() }} >Add Address +</a></p>

                                    </> : <>
                                        <div className="noimg text-center">
                                            <img src="/img/noaddress.png" alt="No Address" className="mb-3" style={{ width: '250px' }} />
                                            <h4>Save Your Address Now!</h4>
                                            <p>Add your home and office addresses and enjoy faster checkout</p>
                                            <button className="btn btn-primary btn-medium mt-4" fdprocessedid="4f6y03" onClick={() => { addressModal() }}><span>Add New Address</span></button>
                                        </div>
                                    </>}

                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <Footer />


            </BrowserView>
            {contextValues.toggleAddressModal && (<AddressModal editAddDetails={userAddressDetails}></AddressModal>)}


        </>

    )
}

export default Address