import React, { useEffect, useRef, useState } from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import { ApiService } from '../../Components/Services/apiservices'
import { BrowserView, MobileView } from 'react-device-detect'
import MobileHeader from '../../Components/Header/mobile_header'
import { useParams } from 'react-router-dom'
import constant from '../../Components/Services/constant'
import ServiceBox from '../../Components/Elements/service_box'
import Loader from "react-js-loader";

function AllServices() {
    const { slug } = useParams()
    const didMountRef = useRef(true)
  
    const [serviceImgurl, setServiceImgUrl] = useState('')
    const [isLoading, setisLoading] = useState(true)
    const [serviceList, setserviceList] = useState([])

    useEffect(() => {
        if (didMountRef.current) {
            getallService()
        }
        didMountRef.current = false
    }, [])

   


    const getallService = () => {
        setisLoading(true)
        ApiService.fetchData("getallservices").then((res) => {
            if (res.status == "success") {
                setserviceList(res.data)
                setServiceImgUrl(res.imgUrl)
                setTimeout(()=>{
                    setisLoading(false)
                }, 500)
              
            }
            else{
                setisLoading(false)
            }
        }).catch((error) => {setisLoading(true) })
    }

    return (
        <>
            <BrowserView>
                <Header />
                {isLoading ? <>
                    <Loader type="spinner-default" bgColor={'orange'} color={'orange'} size={40} />
                
                </>: serviceList && serviceList.length > 0 ? <>
                <div className='section-gap-medium'>
                <div className='container'>
                        <div className='row'>
                            {serviceList.map((item, index) => {
                                return (<>
                                <div className='col-4'>
                                <ServiceBox serviceImgurl={serviceImgurl} service={item} index={index}></ServiceBox>

                                </div>
                                   

                                </>)
                            })}
                        </div>
                    </div>
                </div>
                </> : <div>No Service Found </div>}

               

                <Footer />

            </BrowserView>
            <MobileView >
                <MobileHeader PageName={'All Services'} />
                {isLoading?  <Loader type="spinner-default" bgColor={'orange'} color={'orange'} size={40} />:serviceList && serviceList.length > 0 ? <>
                <div className='section-gap-medium'>
                <div className='container'>
                        <div className='row'>
                            {serviceList.map((item, index) => {
                                return (<>
                                    <div className='col-12' key={index}>
                                       
                                    <ServiceBox serviceImgurl={serviceImgurl} service={item} index={index}></ServiceBox>
                                    </div>

                                </>)
                            })}
                        </div>
                    </div>
                </div>
                </> : <div>No Service Found </div>}
            

                <Footer />
            </MobileView>



        </>
    )
}

export default AllServices