import React, { useEffect, useRef, useState, useContext } from 'react'
import DataContext from '../Elements/context'
import { Modal } from 'react-bootstrap'
import { ApiService } from '../Services/apiservices'
import constant from '../Services/constant'
import { addToCart, addToCartSession } from "../Elements/add_to_cart";
import { toast } from 'react-toastify'
import Loader from "react-js-loader";

function ServicesModal() {
    const contextValues = useContext(DataContext)
    const didMountRef = useRef(true)
    const [serviceData, setServiceData] = useState({})
    const [imageUrl, setImageUrl] = useState("")
    const [variationData, setVariationData] = useState([]);
    const [galleryImage, setgalleryImage] = useState([]);
    const [selvararray, setSelvararray] = useState([]);
    const [arySelectedData, setArySelectedData] = useState([]);
    const [termImageBaseUrl, setTermImageBaseUrl] = useState('')
    useEffect(() => {
        if (didMountRef.current) {
            if (contextValues.productData && Object.keys(contextValues.productData).length > 0) {
                getServiceDetail()
            }
            const userToken= localStorage.getItem('USER_TOKEN')
            if(userToken){
                contextValues.setUserToken(userToken)
            }
        }
        didMountRef.current = false
    }, [])

    const getServiceDetail = () => {
        const dataString = {
            slug: contextValues.productData.service_slug,
        }
        ApiService.postData("servicedetail", dataString).then((res) => {
            if (res.status == "success") {
                setServiceData(res.rowServiceData)
                setTermImageBaseUrl(res.termsImgUrl)
                setImageUrl(res.imgUrl)
                setVariationData(res.variationData);
                setSelvararray(res?.selvararray)
                res.variationData.map((variaItem, index) => {
                    if (variaItem.attributes && Number(variaItem.attributes.attribute_type) == 2) {
                        variaItem.attr_terms.map((attriitem, index) => {  //   Image
                            if (res.selvararray.includes(attriitem.terms_name)) {
                                arySelectedData.push({ attr: variaItem.attributes.attribute_name, terms: attriitem.terms_name, terms_value: attriitem.terms_image, terms_type: 2 });
                            }
                        })
                    }
                    if (variaItem.attributes && Number(variaItem.attributes.attribute_type) == 1) {
                        variaItem.attr_terms.map((attriitem, index) => {  //   Color
                            if (res.selvararray.includes(attriitem.terms_name)) {
                                arySelectedData.push({ attr: variaItem.attributes.attribute_name, terms: attriitem.terms_name, terms_value: attriitem.terms_value, terms_type: 1 });
                            }
                        })
                    }

                    if (variaItem.attributes && Number(variaItem.attributes.attribute_type) == 3) {
                        variaItem.attr_terms.map((attritem, index) => {  //   Text
                            if (res.selvararray.includes(attritem.terms_name)) {
                                arySelectedData.push({ attr: variaItem.attributes.attribute_name, terms: attritem.terms_name, terms_value: attritem.terms_name, terms_type: 3 });
                            }
                        })
                    }

                })



            }
        })
    }




    const serviceModal = () => {
        contextValues.setToggleServiceModal(!contextValues.toggleServiceModal)
    }
    let mrpValue = parseFloat(serviceData?.service_price);
    let sellingPriceValue = parseFloat(serviceData?.service_selling_price);
    let discount = 0;

    if (!isNaN(mrpValue) && !isNaN(sellingPriceValue) && mrpValue > 0) {
        discount = Math.round((((mrpValue - sellingPriceValue) / mrpValue) * 100));
    }
    const variationSelect = (attrTerm, valueVariation) => {
        const updatedData = arySelectedData.map(item => {
            if (item.attr === valueVariation.attributes.attribute_name) {
                let terms_value;
                switch (Number(item.terms_type)) {
                    case 1: //Color
                        terms_value = attrTerm.terms_value;
                        break;
                    case 2: //Image
                        terms_value = attrTerm.terms_image;
                        break;
                    case 3: //Text
                        terms_value = attrTerm.terms_name;
                        break;
                    default:
                        terms_value = item.terms_value;
                }
                return { ...item, terms: attrTerm.terms_name, terms_value, terms_type: item.terms_type };
            }

            return item;
        });
        const dataString = {
            variation: updatedData,
            service_id: serviceData.service_id,
        };
        ApiService.postData("variation-wise-price", dataString).then((res) => {
            setArySelectedData(updatedData);
            serviceData.service_sku = res.data.pv_sku;
            serviceData.service_selling_price = res.data.pv_sellingprice;
            serviceData.service_price = res.data.pv_price;
            serviceData.service_stock = res.data.pv_quantity;
            serviceData.service_moq = res.data.pv_moq;
            serviceData.service_discount = res.data.pv_discount;
            serviceData.service_expected_time = res.data.pv_expected_time
            setServiceData(serviceData);
        });
    };
    const CartModal=()=>{
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }

    const addToCartProcess = async () => {
      
        if (parseFloat(serviceData.service_selling_price) > 0) {
            const productData = {
                service_id: Number(serviceData.service_id),
                service_name: serviceData.service_name,
                service_slug: serviceData.service_slug,
                service_image: serviceData?.service_image ? `${imageUrl}${serviceData?.service_image}` : constant.DEFAULT_IMAGE,
                service_type: Number(serviceData.service_type),
                service_price: parseFloat(serviceData.service_price).toFixed(2),
                service_selling_price: parseFloat(serviceData.service_selling_price).toFixed(2),
                service_discount: serviceData.service_discount,
                service_variation: selvararray,
                service_category_id: serviceData.service_category_id,
                selected_variation: arySelectedData,
                quantity: Number(1),
            };
            contextValues.setSpinnerCubLoader(serviceData.service_id)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    serviceModal()
                    CartModal()
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                    serviceModal()
                    CartModal()
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };



    return (
        <>
            <Modal show={contextValues.toggleServiceModal} onHide={(e) => serviceModal()}>
                <button onClick={(e) => serviceModal()}><i className="ri-close-line"></i></button>
                <div>
                    <img src={serviceData?.service_image ? `${imageUrl}/${serviceData?.service_image}` : constant.DEFAULT_IMAGE} alt="Service Image"  style={{width:'490px'}}/>
                    <h3>{serviceData?.service_name}</h3>
                    <p dangerouslySetInnerHTML={{ __html: serviceData?.service_desc }}></p>
                    <ins>₹{serviceData?.service_selling_price}</ins>
                    {parseFloat(serviceData?.service_price) > parseFloat(serviceData?.service_selling_price) ? <del>₹{serviceData?.service_price}</del> : ''}
                    {discount !== 0 ? <span className='ms-3'>{discount}%</span> : ''}
                </div>
                {variationData && variationData?.length > 0 ? <>
                    <div>
                        {variationData.map((variationitem, variationindex) => {
                            if (variationitem.attributes && variationitem.attributes.attribute_type === 1) {
                                const matchedVariation = arySelectedData.find(
                                    item => item.attr === variationitem.attributes.attribute_name
                                );

                                return (<>
                                    <h6 className="mb-0 tx-14">{variationitem.attributes.attribute_name}</h6>
                                    <ul>
                                        {variationitem.attr_terms.map((variationitemAttr, variationitemAttrindex) => {
                                            const stringIncluded = matchedVariation && matchedVariation.terms === variationitemAttr.terms_name ? true : false;
                                            const className = stringIncluded ? "color active" : "color";
                                            return (
                                                <li
                                                    className={'productVarationMeta'}
                                                    key={variationitemAttrindex}
                                                    onClick={() => { variationSelect(variationitemAttr, variationitem) }}
                                                >
                                                    <span class={className} data-src="/img/default_image.png" href="javascript:void(0)" style={{ background: variationitemAttr.terms_value }}>[]</span>
                                                    {variationitemAttr.terms_name}</li>
                                            );
                                        }
                                        )}

                                    </ul>

                                </>)
                            }
                            else if (variationitem.attributes && variationitem.attributes.attribute_type === 2) {
                                const matchedVariation = arySelectedData.find(
                                    item => item.attr === variationitem.attributes.attribute_name
                                );
                                return (
                                    <>
                                        <h6 className="mb-0 tx-14">{variationitem.attributes.attribute_name}</h6>
                                        <ul>

                                            {variationitem.attr_terms.map((variationitemAttr, variationitemAttrindex) => {
                                                const stringIncluded = matchedVariation && matchedVariation.terms === variationitemAttr.terms_name ? true : false;
                                                const className = stringIncluded ? "swatch active" : "swatch";
                                                return (
                                                    <li
                                                        className={className}
                                                        key={variationitemAttrindex}
                                                        onClick={() => { variationSelect(variationitemAttr, variationitem) }}
                                                    >
                                                        <img src={variationitemAttr.terms_image != null ? termImageBaseUrl + variationitemAttr.terms_image : constant.DEFAULT_IMAGE} style={{ width: '40px', height: '40px' }} />
                                                        {variationitemAttr.terms_name}
                                                    </li>
                                                );
                                            }
                                            )}
                                        </ul>
                                    </>


                                )
                            }
                            else if (variationitem.attributes && variationitem.attributes.attribute_type === 3) {
                                const matchedVariation = arySelectedData.find(
                                    item => item.attr === variationitem.attributes.attribute_name
                                );
                                return (
                                    <>
                                        <h6 className="mb-0 tx-14">{variationitem.attributes.attribute_name}</h6>
                                        <ul>
                                            {variationitem.attr_terms.map((variationitemAttr, variationitemAttrindex) => {
                                                const stringIncluded = matchedVariation && matchedVariation.terms === variationitemAttr.terms_name ? true : false;
                                                const className = stringIncluded ? "text active" : "text";
                                                return (
                                                    <li
                                                        className={className}
                                                        key={variationitemAttrindex}
                                                        onClick={() => { variationSelect(variationitemAttr, variationitem) }}
                                                    >
                                                        {variationitemAttr.terms_name}
                                                    </li>
                                                );
                                            }
                                            )}
                                        </ul>
                                    </>

                                )
                            }


                        })}


                    </div>
                </> : ''}
                {serviceData?.service_highlight ? (
                    <div>
                        <h3>Included</h3>
                        {serviceData?.service_highlight && serviceData?.service_highlight.split("##").map((highlightvalue, indextag) => (
                            <li key={indextag}>{highlightvalue}</li>
                        ))}
                    </div>
                ) : ''}
                {serviceData.service_expected_time && (
                    <div> Expected Time :{serviceData.service_expected_time}</div>
                )}

                <div className="">
                    <button className="" onClick={()=>{addToCartProcess()}}>{contextValues.spinnerCubLoader == serviceData.product_id ? <Loader type="spinner-default" bgColor={'#fff'} color={'#fff'} size={30} /> : 'Add to Cart'}</button>
                </div>

            </Modal>
        </>


    )

 
}

export default ServicesModal