import React, { useContext } from 'react'
import { Modal } from 'react-bootstrap'
import DataContext from '../Elements/context';
import constant from '../Services/constant';



function CategoryModal({ category, onClose,categoryChildren,imageUrl }) {
    const contextValues = useContext(DataContext);

    const opencategoryModal = () => {
        contextValues.setToggleCategoryModal(!contextValues.toggleCategoryModal)
    }

    return (
        <Modal show={true} onHide={onClose}>
            <button onClick={onClose} className="btn-close"></button>
            <Modal.Body>
                <h2>Category Modal</h2>
                <section>
                    <div className='row g-3 cols-xl-5'>
                    {categoryChildren.map((category,index)=>(
                        <a href={`/services/${category.cat_slug}`} className={'categoriesbox item-'+(index+Number(1))}>
                        <div className='categoriesboxInner'>
                        <div className='categoriesboxIcon'>
                                            <img src={category.cat_image ? imageUrl + category.cat_image : constant.DEFAULT_IMAGE} />
                                        </div>
                                <h6 className='title mb-0'>{category.cat_name}</h6>
                            </div>

                        </a>
                    ))}
                    </div>
                </section>

            </Modal.Body>
        </Modal>


    )
}

export default CategoryModal