import React, { useState, useContext, useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { BrowserView, MobileView } from 'react-device-detect'
import MenuModal from '../Modals/menu_modal';
import DataContext from "../Elements/context";
import { ApiService } from '../Services/apiservices';
import LocationModal from '../Modals/location_modal';
import LoginModal from '../Modals/login_modal';
import CartModal from '../Modals/cart_modal';
import Skeleton from 'react-loading-skeleton';
import ServicesModal from '../Modals/services_modal';
import sessionCartData from '../Elements/cart_session_data';
import { toast } from 'react-toastify';


function Header() {
    const contextValues = useContext(DataContext);
    const [settingData, setSettingData] = useState({})
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const [isLoading, setisLoading] = useState(false)
    const [settingImagePath, setSettingImagePath] = useState({})
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [serachData, setsearchData] = useState([])
    const [query, setQuery] = useState('')
    const dataArray = sessionCartData();

    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {

            if (localStorage.getItem("USER_TOKEN")) {
                contextValues.setUserToken(localStorage.getItem("USER_TOKEN"));
                getCartSessionData();
            } else {
                contextValues.setCartSessionData(dataArray[1])
                contextValues.setCartCount(dataArray[1].length)
                contextValues.setCartSummary(dataArray[3])
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }

            getSettingData()

        }
        didMountRef.current = false

    })


    useEffect(() => {
        const selectedCity = JSON.parse(localStorage.getItem('selectedCity'))
        if (selectedCity) {
            contextValues.setselectedCity(selectedCity?.cities_name)
        }

        // Check if the browser supports Geolocation API
        if (navigator.geolocation) {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
                if (result.state === 'granted') {
                    navigator.geolocation.getCurrentPosition(showPosition);
                } else if (result.state === 'prompt') {
                    navigator.geolocation.getCurrentPosition(showPosition, showError);
                } else if (result.state === 'denied') {
                    const selectedCity = JSON.parse(localStorage.getItem('selectedCity'))
                    if (!selectedCity) {
                        locationModal()
                    }
                    // contextValues.setlocationPermissionStatus(false)

                }
            });
        } else {
            alert('Geolocation is not supported by this browser.');
        }
    }, []);

    const showPosition = (position) => {

        contextValues.setlocationPermissionStatus(true)

        setTimeout(() => {
            const selectedCity = JSON.parse(localStorage.getItem('selectedCity'))
            if (selectedCity) {
                contextValues.setselectedCity(selectedCity?.cities_name)
            }
            else if (!selectedCity) {
                contextValues.setcurrentPosition({
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                })
                contextValues.setToggleLocationModal(true)
            }
        }, [500])
    };

    const showError = (error) => {
        const selectedCity = JSON.parse(localStorage.getItem('selectedCity'));
        switch (error.code) {
            case error.PERMISSION_DENIED:
                contextValues.setlocationPermissionStatus(false)
                if (!selectedCity) {
                    locationModal()
                }
                break;
            case error.POSITION_UNAVAILABLE:
                contextValues.setlocationPermissionStatus(false)
                if (!selectedCity) {
                    locationModal()
                }
                break;
            case error.TIMEOUT:
                contextValues.setlocationPermissionStatus(false)
                if (!selectedCity) {
                    locationModal()
                }
                break;
            //   case error.UNKNOWN_ERROR:
            //     toast('An unknown error occurred.');
            //     locationModal()
            //     break;
            default:
                contextValues.setlocationPermissionStatus(false)
                if (!selectedCity) {
                    locationModal()
                }
                break;
        }
    };



    const getSettingData = () => {
        setisLoading(true)
        ApiService.fetchData("setting-data").then((res) => {
            if (res.status === "success") {
                setSettingData(res.sitesettings)
                setSettingImagePath(res.setting_image_path)
                setisLoading(false)

            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }

    //    search fn
    const handleSearch = (e) => {
        setQuery(e.target.value)
        if (e.target.value.length > 2) {
            const dataString = {
                query: e.target.value
            }
            ApiService.postData('getsearchdata', dataString).then((res) => {
                if (res.status == 'success') {
                    setsearchData(res.data)
                }
            }).catch(() => { })
        }
        else {
            setsearchData([])
        }
    }

    // modal handling
    const menuModal = () => {
        contextValues.setToggleMenuModal(!contextValues.toggleMenuModal)
    }
    const locationModal = () => {
        contextValues.setToggleLocationModal(!contextValues.toggleLocationModal)
    }
    const locationOpenModal = () => {
        contextValues.setcurrentPosition({
            lat: '',
            lng: ''
        })
        contextValues.setToggleLocationModal(!contextValues.toggleLocationModal)
    }
    const cartModal = () => {
        contextValues.setToggleCartModal(!contextValues.toggleCartModal)
    }
    const serviceModal = () => {
        contextValues.setToggleServiceModal(!contextValues.toggleServiceModal)
    }
    // cart count 


    const getCartSessionData = () => {
        const dataString = {
            coupon_session: localStorage.getItem("COUPON_SESSION"),
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {

                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                contextValues.setCartSessionData([])
                contextValues.setCartCount(0)
                contextValues.setCartSummary({})
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    }

    return (
        <>
            <BrowserView>
                <header className='header'>
                    {/* <div className='header-top'>

                    </div> */}
                    {isLoading ? <>
                        <div className='header-middle'>
                            <div className='container'>
                                <div className='header-left'>
                                    <a href='javascript:void(0)' className='logo'><Skeleton width={'150px'} height={'50px'}></Skeleton> </a>
                                    <div className='header-location'>  <Skeleton width={'100px'} ></Skeleton> <i className="ri-arrow-down-s-line"></i></div>
                                    <div className="header-search">
                                        <Skeleton width={'500px'} height={'50px'}></Skeleton>

                                    </div>
                                    <div className='main-menu'>
                                        <nav className="navbar navbar-expand-lg navbar-light">
                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                                <li className="nav-item"><a className="nav-link" href="javascript:void(0)"><Skeleton width={'150px'} height={'30px'}></Skeleton></a></li>
                                                <li className="nav-item"><a className="nav-link" href="javascript:void(0)"><Skeleton width={'150px'} height={'30px'}></Skeleton></a></li>

                                            </ul>
                                        </nav>
                                    </div>
                                </div>

                                <div className='header-right justify-content-end'>
                                    <ul className='rightHeaderlist'>
                                        <li>
                                            <a href='javascript:void(0)'
                                            >
                                                <Skeleton width={'30px'} height={'30px'}></Skeleton>
                                            </a>
                                        </li>

                                        <li >
                                            <a href='javascript:void(0)' >
                                                <Skeleton width={'30px'} height={'30px'}></Skeleton>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>



                    </> : <> <div className='header-middle'>
                        <div className='container'>
                            <div className='header-left'>
                                <a href='/' className='logo'><img src={settingData.logo ? settingImagePath + settingData.logo : '/img/logo.png'} alt='logo-image'></img></a>
                                <div className='header-location' onClick={locationOpenModal}>{contextValues.selectedCity} <i className="ri-arrow-down-s-line"></i></div>
                                <div className="header-search">
                                    <form action="#" className="input-wrapper">
                                        <input type="text" placeholder="Search for services" onChange={(e) => { handleSearch(e) }} />
                                        <button className="btn btn-search" type="button">  <i className="ri-search-line"></i></button>
                                    </form>
                                    {serachData && serachData.length > 0 && (
                                        <ul>
                                            {serachData.map((value, index) => {
                                                return (
                                                    <li key={index}><a href={value.redirect}>{value.name}</a></li>
                                                )

                                            })}

                                        </ul>
                                    )}
                                </div>
                                <div className='main-menu'>
                                    <nav className="navbar navbar-expand-lg navbar-light">
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">

                                            <li className="nav-item"><a className="nav-link" href="/services">Services</a></li>
                                            <li className="nav-item"><a className="nav-link" href="/pricing">Pricing</a></li>

                                        </ul>
                                    </nav>
                                </div>
                            </div>

                            <div className='header-right justify-content-end'>
                                <ul className='rightHeaderlist'>
                                    {contextValues.userToken ? (
                                        <li>
                                            <a href='/account/account-overview'>
                                                <i className="ri-user-line"></i>
                                            </a>
                                        </li>
                                    ) : (
                                        <li>
                                            <a href='javascript:void(0)'
                                                onClick={() => { handleShow() }}

                                            >
                                                <i className="ri-user-line"></i>
                                            </a>
                                        </li>

                                    )}

                                    <li onClick={() => { cartModal() }}>
                                        <a href='javascript:void(0)' >
                                            <i className="ri-shopping-bag-3-line"></i>
                                            <span className="csQty">{contextValues?.cartCount}</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div></>}

                </header>

            </BrowserView>

            <MobileView>
                <header className='header'>
                    <div className='header-top'>

                    </div>
                    {isLoading ? <>
                        <div className='header-middle'>
                            <div className='container'>
                                <div className='header-left'>
                                    <ul className='leftHeaderlist'>
                                        <li>
                                            <a href='javascript:void(0)'>
                                                <Skeleton width={'30px'} height={'30px'}></Skeleton>
                                            </a>
                                        </li>
                                    </ul>
                                    <a href='javascript:void(0)' className='logo ml-15'><Skeleton width={'100px'} height={'40px'}></Skeleton></a>
                                </div>
                                <div className='header-right justify-content-end'>
                                    <ul className='rightHeaderlist'>
                                        <li >
                                            <a href='javascript:void(0)' >
                                                <Skeleton width={'30px'} height={'30px'}></Skeleton>
                                            </a>
                                        </li>
                                    </ul>
                                    <div className='header-location ml-15'></div>

                                </div>
                            </div>
                        </div>


                    </> : <><div className='header-middle'>
                        <div className='container'>
                            <div className='header-left'>
                                <ul className='leftHeaderlist'>
                                    <li>
                                        <a href='#'>
                                            <i className="ri-menu-2-fill"></i>
                                        </a>
                                    </li>
                                </ul>
                                <a href='/' className='logo ml-15'><img src={settingData.logo ? settingImagePath + settingData.logo : '/img/logo.png'} alt='logo-image' /></a>
                            </div>
                            <div className='header-right justify-content-end'>
                                <ul className='rightHeaderlist'>
                                    <li onClick={() => { cartModal() }}>
                                        <a href='javascript:void(0)' >
                                            <i className="ri-shopping-bag-3-line"></i>
                                            <span className="csQty">3</span>
                                        </a>
                                    </li>
                                </ul>
                                <div className='header-location ml-15' onClick={locationModal}>{contextValues.selectedCity} <i className="ri-arrow-down-s-line"></i></div>

                            </div>
                        </div>
                    </div></>}


                </header>

            </MobileView>
            {contextValues.toggleLocationModal && <LocationModal />}
            {contextValues.toggleCartModal && <CartModal></CartModal>}
            {contextValues.toggleServiceModal && <ServicesModal></ServicesModal>}

            {show && <LoginModal show={show} setShow={setShow} handleClose={handleClose} handleShow={handleShow}></LoginModal>}
            <Modal show={contextValues.toggleMenuModal} onHide={(e) => menuModal()} className="left menu-modal">
                {contextValues.toggleMenuModal && (<MenuModal />)}
            </Modal>

        </>

    )


}

export default Header