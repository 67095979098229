import React from 'react'
import Header from '../../Components/Header'
import Footer from '../../Components/Footer'
import MobileHeader from '../../Components/Header/mobile_header'
import {BrowserView ,MobileView } from 'react-device-detect'

function Bookings() {
  return (

    <>
      <BrowserView>
        <Header />
        <div>Booking</div>
        <Footer />
      </BrowserView>
      <MobileView>
        <MobileHeader PageName="Booking"/>
        <div>Booking</div>
      </MobileView>

    </>

  )
}

export default Bookings