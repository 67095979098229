import { useContext, useEffect, useRef, useState } from "react";
import sessionCartData from "../../../Components/Elements/cart_session_data";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "react-js-loader";
import { addToCart, addToCartSession, minusToCart, minusToCartSession } from "../../../Components/Elements/add_to_cart";
import { ApiService } from "../../../Components/Services/apiservices";
import multiCurrency from "../../../Components/Elements/multiCurrency";
import DataContext from "../../../Components/Elements/context";
const OrderSummary = () => {
    const dataArray = sessionCartData();
    const [spinnerLoading, setSpinnerLoading] = useState(true);
    const contextValues = useContext(DataContext)
    const [promoCode, setPromoCode] = useState({ promo_code: "", });
    const [variation, setvariation] = useState([])
    const [couponSpinnerLoading, setCouponSpinnerLoading] = useState(false);
    const navigate = useNavigate()
    const didMountRef = useRef(true)
    useEffect(() => {
        if (didMountRef.current) {
            contextValues.setCouponSession(dataArray[2])
            getCartSessionData();
        }
        didMountRef.current = false;
    }, []);

    const getCartSessionData = () => {
        const dataString = {
            coupon_session: localStorage.getItem("COUPON_SESSION"),
        };
        ApiService.postData("cartSessionData", dataString).then((res) => {
            if (res.data.status === "success") {
                contextValues.setCartSessionData(res.data.resCartData)
                contextValues.setCartCount(res.data.resCartData.length)
                contextValues.setCartSummary(res.data.cartSummary)
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            } else {
                contextValues.setCartSessionData([])
                contextValues.setCartCount(0)
                contextValues.setCartSummary({})
                setTimeout(() => {
                    setSpinnerLoading(false);
                }, 500);
            }
        });
    }
    const serviceModal = (data) => {
        contextValues.setProductData(data)
        setTimeout(() => {
            contextValues.setToggleServiceModal(!contextValues.toggleServiceModal)
        }, 100)
    }


    const minusToCartProcess = async (addservice) => {
        if (parseFloat(addservice.service_selling_price) > 0) {
            contextValues.setSpinnerCubLoader(addservice.service_id)
            if (localStorage.getItem("USER_TOKEN")) {
                const updateStatus = await minusToCartSession(addservice, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await minusToCart(addservice, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const addToCartProcess = async (serviceData) => {
      
        if (parseFloat(serviceData.service_selling_price) > 0) {
            const productData = {
                service_id: Number(serviceData.service_id),
                service_name: serviceData.service_name,
                service_slug: serviceData.service_slug,
                service_image: serviceData?.service_image ,
                service_type: Number(serviceData.service_type),
                service_price: parseFloat(serviceData.service_price).toFixed(2),
                service_selling_price: parseFloat(serviceData.service_selling_price).toFixed(2),
                service_discount: serviceData.service_discount,
                service_variation: serviceData.service_variation,
                service_category_id: serviceData.service_category_id,
                selected_variation: serviceData.selected_variation,
                quantity: serviceData.quantity,
            };
            contextValues.setSpinnerCubLoader(serviceData.service_id)
            setvariation(serviceData.selected_variation)
            if (contextValues.userToken) {
                const updateStatus = await addToCartSession(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                   
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            } else {
                const updateStatus = await addToCart(productData, 1, contextValues);
                if (updateStatus) {
                    contextValues.setSpinnerCubLoader(0)
                   
                } else {
                    contextValues.setSpinnerCubLoader(0)
                }
            }
        } else {
            toast.error('Internal Error, Please try later.');
        }
    };

    const onTodoChangePromo = (e) => {
        const { name, value } = e.target;
        setPromoCode((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const removeCoupon = () => {
        localStorage.removeItem("COUPON_SESSION");
        const parsedCouponSession = {
            discount_amount: 0.0,
            promo_id: 0,
            promo_code: "",
        };
        localStorage.setItem("COUPON_SESSION", JSON.stringify(parsedCouponSession));
        contextValues.setCouponSession(parsedCouponSession)
    }

    const processToCheckout = () => {
        navigate('/checkout/address')
    }

  
   

    return (
        <>
            {contextValues.cartSessionData.length > 0 &&
                <div className="right">
                    <div className="rightCheckoutPanel">
                        {contextValues.cartSessionData.map((value, index) => {
                            return (
                                <div className="checkoutProduct">
                                    <div className="checkoutProductMedia">
                                        <a href='javascript:void(0)' onClick={()=>{serviceModal(value)}}>
                                            <img src={value.service_image}></img>
                                        </a>
                                    </div>
                                    <div className="checkoutProductContent">
                                        <h2 className="title">
                                            <a href='javascript:void(0)' onClick={()=>{serviceModal()}}>{value.service_name}</a>
                                        </h2>
                                        {value.service_type == 1 && value.selected_variation && value.selected_variation.length > 0 ?
                                            <>
                                                {value.selected_variation.map((value, index) => {
                                                    return (<div class="tx-gray-500 mb-10 tx-13" key={index}>{value.attr}: {value.terms}</div>)
                                                })}
                                            </>
                                            : null}
                                        <div className="checkoutProductFooter">
                                            <div class="checkoutProduct-price">
                                                <ins class="new-price">{multiCurrency(value.service_selling_price)}</ins>
                                                {Number(value.service_price) > Number(value.service_selling_price) && (<del className="old-price">{multiCurrency(value.service_price)}</del>)}
                                            </div>
                                            <div class="checkoutProductqty">
                                                <span onClick={(e) => minusToCartProcess(value)}><i className="ri-subtract-line"></i></span>
                                                <span>{contextValues.spinnerCubLoader == value.product_id ? <div className='buttonLoader'><Loader type="spinner-default" bgColor={'#121212'} color={'#121212'} size={30} /> </div> : value.quantity}</span>
                                                <span onClick={(e) => addToCartProcess(value)}><i className="ri-add-line"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        <div className="applyofferbox">
                            <input type="text" placeholder="Enter Coupons Code" name="promo_code"   ></input>
                            <button className="applyofferButton btn-primary btn" type="button">{couponSpinnerLoading ? <Loader type="spinner-default" bgColor={'#121212'} color={'#121212'} size={20} /> : 'APPLY'}</button>
                        </div>
                       
                        <ul className="checkputPricebox">
                            {/* <li>
                                <span>Shipping</span>
                                <span>{multiCurrency(contextValues.cartSummary.total_amount && contextValues.settingData.admin_shipping_free && parseFloat(contextValues.settingData.admin_shipping_free) > parseFloat(contextValues.cartSummary.total_amount) ? parseFloat(contextValues.settingData.admin_notzone_amount) : 0)}</span>
                            </li> */}
                            <li>
                                <span>Subtotal ({contextValues.cartSessionData.length} items)</span>
                                <span>{multiCurrency(contextValues.cartSummary.itemTotal)}</span>
                            </li>
                            <li>
                                <span>Discount </span>
                                <span>-{multiCurrency(contextValues.cartSummary.discount)}</span>
                            </li>
                            {contextValues.couponSession && contextValues.couponSession.discount_amount > 0 && (
                                <li>
                                    <span>Coupon Discount </span>
                                    <span>-{multiCurrency(contextValues.couponSession.discount_amount)}</span>
                                </li>
                            )}
                            <li>
                                <span className="tx-16 fw600">Total</span>
                                {/* <span className="tx-16 fw600">{multiCurrency(contextValues.cartSummary.total_amount + (contextValues.cartSummary.total_amount && contextValues.settingData.admin_shipping_free && parseFloat(contextValues.settingData.admin_shipping_free) > parseFloat(contextValues.cartSummary.total_amount) ? parseFloat(contextValues.settingData.admin_notzone_amount) : 0) - (contextValues.couponSession && contextValues.couponSession.discount_amount > 0 ? contextValues.couponSession.discount_amount : 0))}</span> */}
                            </li>
                        </ul>
                    </div>
                </div>
            }
        </>
    )
}

export default OrderSummary