import React, { useEffect, useRef, useState, useContext } from 'react'
import { ApiService } from '../../Components/Services/apiservices'
import constant from '../../Components/Services/constant'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ServicesModal from '../../Components/Modals/services_modal'
import DataContext from '../../Components/Elements/context'
import Skeleton from 'react-loading-skeleton';
import ServiceBox from '../../Components/Elements/service_box';

function HomeFeaturedServices() {
    const didMountRef = useRef(true)
    const contextValues = useContext(DataContext)
    const [isLoading, setisLoading] = useState(false)
    const [featuredService, setFeaturedService] = useState([])
    const [imageUrl, setImageUrl] = useState("")



    useEffect(() => {
        if (didMountRef.current) {
            getFeaturedService()
        }
        didMountRef.current = false
    }, [])


    const getFeaturedService = () => {
        setisLoading(true)
        ApiService.fetchData("featuredService").then((res) => {
            if (res.status == "success") {
                setisLoading(false)
                setFeaturedService(res.data)
                setImageUrl(res.imgUrl)
            }
            else {
                setisLoading(false)
            }
        }).catch(() => {
            setisLoading(false)
        })
    }


    return (
        <>
            {isLoading ? <>
                <section className='section-gap-medium'>
                    <div className="container">
                        <div className="section-title text-center mb-3">
                            <h2><Skeleton width={'200px'} ></Skeleton></h2>
                        </div>
                        <div className='row'>
                        {[...Array(3)].map((_, index) => {
                                    return (<>
                                       <div className='col-4'>
                            <div className="tile">
                                <picture>
                                   <Skeleton width={'400px'} height={'250px'}></Skeleton>
                                </picture>
                                <div className="tile-body">
                                    <h5><Skeleton width={'200px'} ></Skeleton></h5>
                                    <p><Skeleton width={'100px'} ></Skeleton></p>
                                  
                                    <div className="price">
                                        <del><Skeleton width={'50px'}></Skeleton></del>
                                        
                                    </div>
                                </div>
                            </div>
                            </div>

                                    </>)
                                })}
                        </div>
                    </div>
                </section>
            </> : <> {featuredService && featuredService.length > 0 ? (
                <section className='section-gap-medium'>
                    <div className="container">
                        <div className="section-title text-center mb-3">
                            <h2>Our Services</h2>
                        </div>

                        <Swiper
                            spaceBetween={20}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1,
                                },
                                320: {
                                    slidesPerView: 1,
                                },
                                575: {
                                    slidesPerView: 2,
                                },
                                767: {
                                    slidesPerView: 3,
                                },
                                992: {
                                    slidesPerView: 3,
                                },
                                1000: {
                                    slidesPerView: 3,
                                },
                                1500: {
                                    slidesPerView: 3,
                                },
                                1700: {
                                    slidesPerView: 4
                                }
                            }}
                        >
                            {featuredService.map((service, index) => {
                                let mrpValue = parseFloat(service.service_price);
                                let sellingPriceValue = parseFloat(service.service_selling_price);
                                let discount = 0;

                                if (!isNaN(mrpValue) && !isNaN(sellingPriceValue) && mrpValue > 0) {
                                    discount = Math.round((((mrpValue - sellingPriceValue) / mrpValue) * 100));
                                }
                                return (
                                    <SwiperSlide>
                                        <ServiceBox service={service} index={index} serviceImgurl={imageUrl} ></ServiceBox>
                                    </SwiperSlide>

                                )
                            })}
                        </Swiper>
                    </div>

                </section>
            ) : null}</>}



        </>
    )
}

export default HomeFeaturedServices